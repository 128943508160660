import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getUserInfo } from "../../actions/usersActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

const UserSingle = ({ getUserInfo, match, history, loading, user }) => {
  const fetchData = async () => {
    const response = await getUserInfo(match.params.id);

    if (response !== 200) {
      history.push("/users");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de administrador" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row>
            <Col sm={12} md={4}>
              <ElementWrapper className="p-3">
                {user.avatar && (
                  <div className="my-3">
                    <img
                      src={user.avatar}
                      alt={user.name}
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                )}

                <div className="my-3">
                  <p className="m-0">Nombre: {user.name || "N/A"}</p>
                </div>

                <div className="my-3">
                  <p className="m-0">Email: {user.email || "N/A"}</p>
                </div>

                <div className="my-3">
                  <p className="m-0">Teléfono: {user.phone || "N/A"}</p>
                </div>
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

UserSingle.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getUserInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.users.user,
  loading: state.users.loading
});

export default connect(
  mapStateToProps,
  { getUserInfo }
)(UserSingle);
