import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getPharmacyInfo } from "../../actions/pharmacieActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import Map from "../Map";

const PharmacySingle = ({
  getPharmacyInfo,
  match,
  history,
  pharmacy,
  loading
}) => {
  const fetchData = async () => {
    const response = await getPharmacyInfo(match.params.id);

    if (response !== 200) {
      history.push("/pharmacies");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Farmacia" loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {pharmacy.name || "N/A"}</p>
                </div>
                <div className="my-3">
                  <p className="m-0">Dirección: {pharmacy.address || "N/A"}</p>
                </div>
                {pharmacy.location && (
                  <>
                    <div className="my-3">
                      <p className="m-0">
                        Provincia:{" "}
                        {pharmacy.location.area.province.name || "N/A"}
                      </p>
                    </div>
                    <div className="my-3">
                      <p className="m-0">
                        Localidad: {pharmacy.location.area.name || "N/A"}
                      </p>
                    </div>
                    <div className="my-3">
                      <p className="m-0">
                        Barrio: {pharmacy.location.name || "N/A"}
                      </p>
                    </div>
                  </>
                )}
                <div className="my-3">
                  <p className="m-0">
                    Código Postal: {pharmacy.postcode || "N/A"}
                  </p>
                </div>
                <div className="my-3">
                  <p className="m-0">Teléfono: {pharmacy.phone || "N/A"}</p>
                </div>
              </ElementWrapper>
            </Col>
            <Col sm={6}>
              <Map
                center={{ lat: pharmacy.lat, lng: pharmacy.lon }}
                zoom={15}
                height={500}
              />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

PharmacySingle.propTypes = {
  getPharmacyInfo: PropTypes.func.isRequired,
  pharmacy: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  pharmacy: state.pharmacies.pharmacy,
  loading: state.pharmacies.loading
});

export default connect(
  mapStateToProps,
  { getPharmacyInfo }
)(PharmacySingle);
