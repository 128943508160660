import React, { Fragment } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

const LoginWrapper = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginLayout = ({ children }) => {
  return (
    <Fragment>
      <LoginWrapper>
        <Container>
          <Row>
            <Col
              xs={12}
              sm={{ size: "8", offset: 2 }}
              md={{ size: "6", offset: 3 }}
              xl={{ size: "4", offset: 4 }}
            >
              {children}
            </Col>
          </Row>
        </Container>
      </LoginWrapper>
    </Fragment>
  );
};

export default LoginLayout;
