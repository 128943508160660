import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getMET, deleteMET } from "../../actions/medicalEntity";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const ServicesItem = ({ getMET, filters, deleteMET, description, id }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);
    const response = await deleteMET(id);
    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }
    await getMET(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{description}</td>
      <td>
        <TableItemActions
          pathName="medical_entity_type"
          id={id}
          toggle={toggle}
        />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading
                ? "Eliminando"
                : "Se eliminará este tipo de centro médico"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

const mapStateToProps = state => ({
  filters: state.medicalEntityTypes.filters
});

export default connect(
  mapStateToProps,
  {
    getMET,
    deleteMET
  }
)(ServicesItem);
