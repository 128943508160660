import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import FormTypesContainer from "./FormTypesContainer";
import FormTypesCreate from "./FormTypesCreate";
import FormTypesEdit from "./FormTypesEdit";
import FormTypesDetail from "./FormTypesDetail";

const FormTypesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={FormTypesContainer} />
      <Route path={`${match.path}/add`} component={FormTypesCreate} />
      <Route path={`${match.path}/:id/edit`} component={FormTypesEdit} />
      <Route path={`${match.path}/:id`} component={FormTypesDetail} />
    </Switch>
  </Fragment>
);

export default FormTypesRoutes;
