import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import MServicesContainer from "./MServicesContainer";
import MServicesCreate from "./MServicesCreate";
import MServicesEdit from "./MServicesEdit";
import MServicesDetail from "./MServicesDetail";

const MServicesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={MServicesContainer} />
      <Route path={`${match.path}/add`} component={MServicesCreate} />
      <Route path={`${match.path}/:id/edit`} component={MServicesEdit} />
      <Route path={`${match.path}/:id`} component={MServicesDetail} />
    </Switch>
  </Fragment>
);

export default MServicesRoutes;
