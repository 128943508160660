import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";

import LoginLayout from "../layouts/LoginLayout";
import LoginForm from "../components/Login/LoginForm";

const Login = ({ match, auth, history }) => {
  useEffect(() => {
    if (auth) {
      history.push("/");
    }
  }, []);

  return (
    <LoginLayout>
      <Switch>
        <Route exact path={match.path} component={LoginForm} />
      </Switch>
    </LoginLayout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  null
)(Login);
