import axios from "axios";
import { API_URL } from "../api";
import {
  MEMBERS_LOADING,
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_MEMBERS_FAILURE,
  GET_SINGLE_MEMBER_SUCCESS,
  GET_SINGLE_MEMBER_FAILURE,
  SET_MEMBERS_SEARCH_QUERY,
  SET_MEMBERS_PAGE,
  RESET_MEMBERS_REDUCER
} from "./types/membersTypes";
import { SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";

export const getMembers = filters => async dispatch => {
  const { page, query } = filters;

  try {
    dispatch({ type: MEMBERS_LOADING });

    const endPoint = `${API_URL}/api/admin/member`;
    const pageParams = `?page=${page}`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&full_name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_ALL_MEMBERS_SUCCESS,
      payload: {
        members: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_MEMBERS_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error;
  }
};

export const getMemberInfo = id => async dispatch => {
  try {
    dispatch({ type: MEMBERS_LOADING });

    const response = await axios.get(`${API_URL}/api/admin/member/${id}`);

    const orderedTurnos = response.data.member.turnos.sort(function(a, b) {
      return new Date(b.fecha) - new Date(a.fecha);
    });

    const memberData = {
      ...response.data.member,
      turnos: orderedTurnos
    };

    dispatch({
      type: GET_SINGLE_MEMBER_SUCCESS,
      payload: {
        member: memberData
      }
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_MEMBER_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error;
  }
};

export const setSearchQuery = query => ({
  type: SET_MEMBERS_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_MEMBERS_PAGE,
  payload: {
    value: page
  }
});

export const resetMembers = () => ({
  type: RESET_MEMBERS_REDUCER
});
