import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getMedicalServices,
  setSearchQuery,
  setPage
} from "../../actions/mservices";

import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PanelHead from "../PanelHead";
import PageMeta from "../PageMeta";
import LoaderWrapper from "../styles/LoaderWrapper";
import Loader from "../Loader";
import Paginator from "../Paginator";
import MServicesList from "./MServicesList";

class MServicesContainer extends Component {
  searchField = createRef();

  handleSubmitData = async () => {
    await this.props.getMedicalServices(this.props.filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  render() {
    const { meta, filters, loading, links, services } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs
                title="Servicios Médicos"
                add={true}
                url="/medical_services/add"
              />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                loading={loading}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <MServicesList services={services} />
            )}

            {services.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={links.first}
                last={links.last}
                next={links.next}
                prev={links.prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

MServicesContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired
};

const mapStateToprops = state => ({
  loading: state.mservices.loading,
  filters: state.mservices.filters,
  services: state.mservices.services,
  meta: state.mservices.meta,
  links: state.mservices.links
});

export default connect(mapStateToprops, {
  getMedicalServices,
  setSearchQuery,
  setPage
})(MServicesContainer);
