import axios from "axios";
import { API_URL } from "../api";
import {
  MSERVICES_LOADING,
  GET_MSERVICES_SUCCESS,
  GET_MSERVICES_FAILURE,
  SET_MSERVICES_SEARCH_QUERY,
  SET_MSERVICES_PAGE,
  GET_MSERVICE_DETAIL_SUCCESS,
  GET_MSERVICE_DETAIL_FAILURE,
  RESET_MSERVICES_REDUCER
} from "./types/mservices";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";
import AlphabeticOrder from "../lib/AlphabeticOrder";

export const getMedicalServices = (filters, parsed) => async dispatch => {
  try {
    let servicesData;
    dispatch({ type: MSERVICES_LOADING });

    const endPoint = `${API_URL}/api/admin/medical_service`;
    const pageParams = `?page=${filters &&
      filters.page}&orderBy=description&orderByDir=asc`;

    let URL = `${endPoint}${parsed ? "?pagination=300" : pageParams}`;

    if (filters && filters.query.length > 0) {
      const searchParams = `&description=${filters.query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    if (!parsed) {
      servicesData = response.data.data;
    } else {
      servicesData = AlphabeticOrder(response.data.data, "description").map(
        item => ({
          value: item.id,
          label: item.description
        })
      );
    }

    dispatch({
      type: GET_MSERVICES_SUCCESS,
      payload: {
        services: servicesData,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_MSERVICES_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }
    return error.response.status;
  }
};

export const getMService = id => async dispatch => {
  try {
    dispatch({ type: MSERVICES_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/medical_service/${id}`
    );

    dispatch({
      type: GET_MSERVICE_DETAIL_SUCCESS,
      payload: {
        service: response.data.medicalService
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_MSERVICE_DETAIL_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addMService = service => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/medical_service`,
      service
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Servicio Médico creado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateMService = (id, service) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/medical_service/${id}`,
      service
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Servicio Médico fue editado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteMService = id => async dispatch => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/admin/medical_service/${id}`
    );

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Servicio Médico eliminada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_MSERVICES_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_MSERVICES_PAGE,
  payload: {
    value: page
  }
});

export const resetMServices = () => ({
  type: RESET_MSERVICES_REDUCER
});
