import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getProvince } from "../../actions/provinces";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import Map from "../Map";

const ProvincesDetails = ({
  getProvince,
  match,
  history,
  loading,
  province
}) => {
  const fetchData = async () => {
    const response = await getProvince(match.params.id);

    if (response !== 200) {
      history.push("/provinces");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title={province.name} loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={12}>
              <Map
                center={{ lat: province.lat, lng: province.lon }}
                zoom={15}
                height={500}
              />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

ProvincesDetails.propTypes = {
  getProvince: PropTypes.func.isRequired,
  province: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  province: state.provinces.province,
  loading: state.provinces.loading
});

export default connect(
  mapStateToProps,
  { getProvince }
)(ProvincesDetails);
