import styled from "styled-components";

const LoaderWrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fbfbfb;
`;

export default LoaderWrapper;
