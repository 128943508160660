import {
  SPECIALTIES_LOADING,
  GET_ALL_SPECIALTIES_SUCCESS,
  GET_ALL_SPECIALTIES_FAILURE,
  GET_SPECIALTY_SUCCESS,
  GET_SPECIALTY_FAILURE,
  SET_SPECIALTIES_SEARCH_QUERY,
  SET_SPECIALTIES_PAGE,
  RESET_SPECIALTIES_REDUCER
} from "../actions/types/specialtiesTypes";

import {
  GET_SPECIALTIES_FOR_CLINICS_SUCCESS,
  GET_SPECIALTIES_FOR_CLINICS_FAILURE,
  SPECIALTIES_CLINICS_LOADING
} from "../actions/types/clinicsTypes";

const initialState = {
  specialties: [],
  specialty: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SPECIALTIES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case SPECIALTIES_CLINICS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_SPECIALTIES_FOR_CLINICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        specialties: [...action.payload.specialties]
      };
    }

    case GET_SPECIALTIES_FOR_CLINICS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_ALL_SPECIALTIES_SUCCESS: {
      return {
        ...state,
        specialties: action.payload.specialties,
        loading: false,
        links: {
          ...state.links,
          currentPage: action.payload.links.currentPage,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_ALL_SPECIALTIES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SPECIALTY_SUCCESS: {
      return {
        ...state,
        specialty: action.payload.specialty,
        loading: false
      };
    }

    case GET_SPECIALTY_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_SPECIALTIES_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_SPECIALTIES_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_SPECIALTIES_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
