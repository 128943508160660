export const CLINICS_LOADING = "CLINICS_LOADING";

export const GET_ALL_CLINICS_SUCCESS = "GET_ALL_CLINICS_SUCCESS";
export const GET_ALL_CLINICS_FAILURE = "GET_ALL_CLINICS_FAILURE";

export const GET_SINGLE_CLINIC_SUCCESS = "GET_SINGLE_CLINIC_SUCCESS";
export const GET_SINGLE_CLINIC_FAILURE = "GET_SINGLE_CLINIC_FAILURE";

export const DELETE_CLINIC_SUCCESS = "DELETE_CLINIC_SUCCESS";
export const DELETE_CLINIC_FAILURE = "DELETE_CLINIC_FAILURE";

export const GET_SPECIALTIES_FOR_CLINICS_SUCCESS =
  "GET_SPECIALTIES_FOR_CLINICS_SUCCESS";
export const GET_SPECIALTIES_FOR_CLINICS_FAILURE =
  "GET_SPECIALTIES_FOR_CLINICS_FAILURE";

export const SPECIALTIES_CLINICS_LOADING = "SPECIALTIES_CLINICS_LOADING";
export const GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS =
  "GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS";

export const GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE =
  "GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE";

export const SET_CLINICS_SEARCH_QUERY = "SET_CLINICS_SEARCH_QUERY";
export const SET_CLINICS_PAGE = "SET_CLINICS_PAGE";

export const GET_CLINIC_TYPE_LOADING = "GET_CLINIC_TYPE_LOADING";
export const GET_CLINICS_TYPES_SUCCESS = "GET_CLINICS_TYPES_SUCCESS";
export const GET_CLINICS_TYPES_FAILURE = "GET_CLINICS_TYPES_FAILURE";

export const RESET_CLINICS_REDUCER = "RESET_CLINICS_REDUCER";
