import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS
} from "../actions/types/authTypes";

import {
  UPLOAD_IMAGE_SUCCESS,
  DELETE_IMAGE_COMPLETE_SUCCESS,
  SET_UPDATED_NAME_AUTH
} from "../actions/types/usersTypes";

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuth,
        user: action.payload.userInfo
      };
    }

    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload.avatar
        }
      };
    }

    case DELETE_IMAGE_COMPLETE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          avatar: null
        }
      };
    }

    case LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuth,
        user: action.payload.userInfo
      };
    }

    case SET_UPDATED_NAME_AUTH: {
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.value
        }
      };
    }

    default:
      return state;
  }
};
