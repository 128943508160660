import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getLocations, deleteLocation } from "../../actions/locations";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const LocationsItem = ({ location, getLocations, filters, deleteLocation }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteLocation(location.id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getLocations(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{location.name}</td>
      <td>{location.area ? location.area.name : "-"}</td>
      <td>{location.area ? location.area.province.name : "-"}</td>
      <td>
        <TableItemActions
          pathName="locations"
          id={location.id}
          toggle={toggle}
        />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará este barrio"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

LocationsItem.propTypes = {
  location: PropTypes.object.isRequired,
  getLocations: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.locations.filters
});

export default connect(
  mapStateToProps,
  { getLocations, deleteLocation }
)(LocationsItem);
