import axios from "axios";
import { API_URL } from "../api";
import {
  SPECIALTIES_LOADING,
  GET_ALL_SPECIALTIES_SUCCESS,
  GET_ALL_SPECIALTIES_FAILURE,
  GET_SPECIALTY_SUCCESS,
  GET_SPECIALTY_FAILURE,
  SET_SPECIALTIES_SEARCH_QUERY,
  SET_SPECIALTIES_PAGE,
  RESET_SPECIALTIES_REDUCER
} from "./types/specialtiesTypes";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import _ from "lodash";
import { logout } from "./authActions";

export const getSpecialties = filters => async dispatch => {
  const { page, query } = filters;

  try {
    dispatch({ type: SPECIALTIES_LOADING });

    const endPoint = `${API_URL}/api/admin/medical_specialty`;
    const pageParams = `?page=${page}&orderBy=description&orderByDir=asc`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&description=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_ALL_SPECIALTIES_SUCCESS,
      payload: {
        specialties: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SPECIALTIES_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return "error";
  }
};

export const getSpecialtyInfo = id => async dispatch => {
  try {
    dispatch({ type: SPECIALTIES_LOADING });
    const response = await axios.get(
      `${API_URL}/api/admin/medical_specialty/${id}`
    );

    if (_.isEmpty(response.data)) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [`Ocurrió un error interno. Inténtelo más tarde.`]
      });
      return "error";
    }

    dispatch({
      type: GET_SPECIALTY_SUCCESS,
      payload: {
        specialty: response.data.medicalSpecialty
      }
    });

    return response.status;
  } catch (error) {
    dispatch({
      type: GET_SPECIALTY_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addSpecialty = specialty => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/medical_specialty`,
      specialty
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [
          `La especialidad, ${specialty.description} fue agregado satisfactoriamente.`
        ]
      });
    }
    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateSpecialty = (id, specialty) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/medical_specialty/${id}`,
      specialty
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`${specialty.description} fue editado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteSpecialty = id => async dispatch => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/admin/medical_specialty/${id}`
    );

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Registro eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_SPECIALTIES_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_SPECIALTIES_PAGE,
  payload: {
    value: page
  }
});

export const resetSpecialties = () => ({
  type: RESET_SPECIALTIES_REDUCER
});
