import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../api";
import VirtualizedSelect from "react-virtualized-select";

const FormTypes = ({ onChange }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/admin/contact_form_type?pagination=100`
      );

      if (response.status === 200) {
        const parsedData = response.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          };
        });

        setData([...data, ...parsedData]);
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <VirtualizedSelect
      value={value}
      options={data}
      onChange={async value => {
        onChange(value);
        setValue(value);
      }}
      placeholder="Filtrar por tipo de Formulario"
      isLoading={loading}
      className="formtype-filter"
    />
  );
};

export default FormTypes;
