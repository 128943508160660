import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Select from "react-select";

import { connect } from "react-redux";
import { getAreaById, updateArea } from "../../actions/areas";
import { getProvinces } from "../../actions/provinces";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";
import GooglePlacesField from "../GooglePlacesField";
import Map from "../Map";
import Loader from "../Loader";
import LoaderWrapper from "../styles/LoaderWrapper";

const AreasEdit = ({
  history,
  getProvinces,
  provinces,
  loading,
  getAreaById,
  match,
  areaLoading,
  area,
  updateArea
}) => {
  const [latLong, setLatLong] = useState({
    lat: 0,
    lng: 0
  });

  const [parsedProvinces, setParsedProvinces] = useState([]);

  const fetchData = async () => {
    const response = await getAreaById(match.params.id);

    if (response !== 200) {
      history.push("/areas");
      return;
    }
  };

  useEffect(() => {
    setLatLong({
      ...latLong,
      lat: area.lat,
      lng: area.lon
    });
  }, [area]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchProvinces = async () => {
    await getProvinces(null, true);
  };

  useEffect(() => {
    fetchProvinces();
  }, [provinces.length]);

  useEffect(() => {
    setParsedProvinces(
      provinces.map(province => ({ value: province.id, label: province.name }))
    );
  }, [provinces.length]);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Editar Localidad" />
        </ContainerHeader>
        {loading || areaLoading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: area.name || "",
                    address: area.name || "",
                    lat: area.lat || "",
                    lon: area.lon || "",
                    province_id: area.province ? area.province.id : ""
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const newValues = {
                      name: values.name,
                      lat: values.lat,
                      lon: values.lon,
                      province_id: values.province_id
                    };

                    const response = await updateArea(
                      match.params.id,
                      newValues
                    );
                    if (response !== 200) {
                      setSubmitting(false);
                      return;
                    }
                    setSubmitting(false);
                    history.push(`/areas`);
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Requerido."),
                    address: Yup.string().required("Requerido."),
                    province_id: Yup.string().required("Requerido.")
                  })}
                  render={({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  }) => {
                    const handleAddressSelect = async place => {
                      setFieldValue("address", place);
                      const results = await geocodeByAddress(place);
                      const { lat, lng } = await getLatLng(results[0]);

                      setLatLong({
                        ...latLong,
                        lat,
                        lng
                      });

                      setFieldValue("lat", lat);
                      setFieldValue("lon", lng);
                    };

                    return (
                      <Form>
                        <fieldset
                          disabled={isSubmitting}
                          aria-busy={isSubmitting}
                        >
                          <FormGroup>
                            <Label for="name">Nombre:</Label>
                            <Input
                              id="name"
                              type="text"
                              placeholder="Ingrese nombre de la Localidad"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.name &&
                                touched.name &&
                                "is-invalid"}`}
                            />
                            {errors.name && touched.name && (
                              <FormFeedback className="d-block">
                                {errors.name}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <GooglePlacesField
                            label="Dirección:"
                            setFieldValue={setFieldValue}
                            value={values.address}
                            onSelect={handleAddressSelect}
                            errors={errors}
                            touched={touched}
                          />

                          <FormGroup>
                            <Label for="province_id">Provincia:</Label>
                            {loading ? (
                              <div className="my-1 px-2">
                                <Spinner size="sm" />
                              </div>
                            ) : (
                              <>
                                <Select
                                  id="province_id"
                                  value={
                                    parsedProvinces
                                      ? parsedProvinces.find(
                                          province =>
                                            province.value ===
                                            values.province_id
                                        )
                                      : ""
                                  }
                                  options={parsedProvinces}
                                  onChange={async value => {
                                    const id = value.value;
                                    setFieldValue("province_id", id);
                                  }}
                                  placeholder="Seleccione la provincia"
                                  onBlur={handleBlur}
                                  className={
                                    errors.province_id && touched.province_id
                                      ? "select-error"
                                      : ""
                                  }
                                />
                                {errors.province_id && touched.province_id && (
                                  <FormFeedback className="d-block">
                                    {errors.province_id}
                                  </FormFeedback>
                                )}
                              </>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <PrimaryBtn type="submit" disabled={isSubmitting}>
                              {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                            </PrimaryBtn>
                          </FormGroup>
                        </fieldset>
                      </Form>
                    );
                  }}
                />
              </ElementWrapper>
            </Col>
            <Col sm={6}>
              <Map center={latLong} zoom={15} />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

const mapStateToProps = state => ({
  loading: state.provinces.loading,
  provinces: state.provinces.provinces,
  area: state.areas.area,
  areaLoading: state.areas.loading
});

export default connect(
  mapStateToProps,
  {
    getProvinces,
    getAreaById,
    updateArea
  }
)(AreasEdit);
