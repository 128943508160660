import {
  SHOW_ALERT_SUCCESS,
  SHOW_ALERT_ERROR,
  HIDE_ALERT
} from "../actions/types/alertTypes";

const initialState = {
  type: "",
  message: [],
  show: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT_SUCCESS: {
      return {
        ...state,
        type: "success",
        message: action.message,
        show: true
      };
    }

    case SHOW_ALERT_ERROR: {
      return {
        ...state,
        type: "error",
        message: action.message,
        show: true
      };
    }

    case HIDE_ALERT: {
      return {
        ...state,
        type: "",
        message: [],
        show: false
      };
    }

    default:
      return state;
  }
};
