import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import LocationsContainer from "./LocationsContainer";
import LocationsCreate from "./LocationsCreate";
import LocationsEdit from "./LocationsEdit";
import LocationDetails from "./LocationsDetails";

const LocationsRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={LocationsContainer} />
      <Route path={`${match.path}/add`} component={LocationsCreate} />
      <Route path={`${match.path}/:id/edit`} component={LocationsEdit} />
      <Route path={`${match.path}/:id`} component={LocationDetails} />
    </Switch>
  </Fragment>
);

export default LocationsRoutes;
