import styled from "styled-components";

const TogglerBtn = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: ${props => props.theme.primaryColor};
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    display: flex;
  }
`;

export default TogglerBtn;
