import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { updateMService, getMService } from "../../actions/mservices";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";
import LoaderWrapper from "../styles/LoaderWrapper";
import Loader from "../Loader";

const MServicesEdit = ({
  updateMService,
  history,
  getMService,
  match,
  loading,
  service
}) => {
  const fetchData = async () => {
    const response = await getMService(match.params.id);

    if (response !== 200) {
      history.push("/medical_services");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Editar Servicio Médico" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    description: service.description || ""
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const response = await updateMService(
                      match.params.id,
                      values
                    );
                    if (response !== 200) {
                      setSubmitting(false);
                      return;
                    }
                    setSubmitting(false);
                    history.push(`/medical_services`);
                  }}
                  validationSchema={Yup.object().shape({
                    description: Yup.string().required("Requerido.")
                  })}
                  render={({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur
                  }) => {
                    return (
                      <Form>
                        <fieldset
                          disabled={isSubmitting}
                          aria-busy={isSubmitting}
                        >
                          <FormGroup>
                            <Label for="description">Nombre:</Label>
                            <Input
                              id="description"
                              type="text"
                              placeholder="Ingrese un nombre"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.description &&
                                touched.description &&
                                "is-invalid"}`}
                            />
                            {errors.description && touched.description && (
                              <FormFeedback className="d-block">
                                {errors.description}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <PrimaryBtn type="submit" disabled={isSubmitting}>
                              {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                            </PrimaryBtn>
                          </FormGroup>
                        </fieldset>
                      </Form>
                    );
                  }}
                />
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

const mapStateToProps = state => ({
  loading: state.mservices.loading,
  service: state.mservices.service
});

export default connect(
  mapStateToProps,
  {
    updateMService,
    getMService
  }
)(MServicesEdit);
