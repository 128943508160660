import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ListGroup, ListGroupItem } from "reactstrap";

// Icons
import { IconContext } from "react-icons";

import { connect } from "react-redux";
import { resetForms } from "../actions/forms";
import { resetMembers } from "../actions/membersActions";
import { resetClicnics } from "../actions/clinicsActions";
import { resetSpecialties } from "../actions/specialtiesActions";
import { resetMServices } from "../actions/mservices";
import { resetPharmacies } from "../actions/pharmacieActions";
import { resetLocations } from "../actions/locations";
import { resetAreas } from "../actions/areas";
import { resetProvinces } from "../actions/provinces";
import { resetUsers } from "../actions/usersActions";
import MenuCollapsable from "./MenuCollapsable";

const ItemLink = styled(NavLink)`
  display: block;
  padding: 1em;
  font-size: 0.85rem;

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const ItemLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: all 200ms ease;
`;

const IconText = styled.p`
  flex: 0 0 80%;
  margin: 0;
  color: ${props => props.theme.whiteColor};
`;

const SidebarNavItem = ({
  resetForms,
  location,
  resetMembers,
  resetClicnics,
  resetSpecialties,
  resetMServices,
  resetLocations,
  resetAreas,
  resetProvinces,
  resetUsers
}) => {
  return (
    <IconContext.Provider
      value={{
        color: "white",
        size: "2em"
      }}
    >
      <li>
        <ItemLink exact to="/">
          <ItemLinkWrapper>
            <IconText>Inicio</IconText>
          </ItemLinkWrapper>
        </ItemLink>
      </li>

      <li>
        <ItemLink
          exact
          to="/affiliates"
          onClick={() => {
            if (location.pathname.includes("/affiliates")) {
              return;
            }

            resetMembers();
          }}
        >
          <ItemLinkWrapper>
            <IconText>Afiliados</IconText>
          </ItemLinkWrapper>
        </ItemLink>
      </li>

      <li>
        <ListGroup className="list-group-menu">
          <ListGroupItem>
            <MenuCollapsable title="Cartilla Medica">
              <ItemLink
                exact
                to="/clinics"
                onClick={() => {
                  if (location.pathname.includes("/clinics")) {
                    return;
                  }
                  resetClicnics();
                }}
              >
                <ItemLinkWrapper>
                  <IconText>Centros Médicos</IconText>
                </ItemLinkWrapper>
              </ItemLink>

              <ItemLink
                exact
                to="/specialties"
                onClick={() => {
                  if (location.pathname.includes("/specialties")) {
                    return;
                  }
                  resetSpecialties();
                }}
              >
                <ItemLinkWrapper>
                  <IconText>Especialidades</IconText>
                </ItemLinkWrapper>
              </ItemLink>

              <ItemLink
                exact
                to="/medical_services"
                onClick={() => {
                  if (location.pathname.includes("/medical_services")) {
                    return;
                  }
                  resetMServices();
                }}
              >
                <ItemLinkWrapper>
                  <IconText>Servicios Médicos</IconText>
                </ItemLinkWrapper>
              </ItemLink>

              <ItemLink exact to="/medical_entity_type">
                <ItemLinkWrapper>
                  <IconText>Tipos de Centros Médicos</IconText>
                </ItemLinkWrapper>
              </ItemLink>
            </MenuCollapsable>
          </ListGroupItem>
        </ListGroup>
      </li>

      <li>
        <ItemLink
          exact
          to="/pharmacies"
          onClick={() => {
            if (location.pathname.includes("/pharmacies")) {
              return;
            }
            resetPharmacies();
          }}
        >
          <ItemLinkWrapper>
            <IconText>Farmacias</IconText>
          </ItemLinkWrapper>
        </ItemLink>
      </li>

      <ListGroup className="list-group-menu">
        <ListGroupItem>
          <MenuCollapsable title="Contacto">
            <ItemLink
              exact
              to="/forms"
              onClick={() => {
                if (location.pathname.includes("forms")) {
                  return;
                }

                resetForms();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Formularios de Contacto</IconText>
              </ItemLinkWrapper>
            </ItemLink>
            <ItemLink
              exact
              to="/form_types"
              onClick={() => {
                if (location.pathname.includes("form_types")) {
                  return;
                }

                resetForms();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Tipos de Formularios</IconText>
              </ItemLinkWrapper>
            </ItemLink>
          </MenuCollapsable>
        </ListGroupItem>
      </ListGroup>

      <ListGroup className="list-group-menu">
        <ListGroupItem>
          <MenuCollapsable title="Configuración">
            <ItemLink
              exact
              to="/provinces"
              onClick={() => {
                if (location.pathname.includes("/provinces")) {
                  return;
                }
                resetProvinces();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Provincias</IconText>
              </ItemLinkWrapper>
            </ItemLink>

            <ItemLink
              exact
              to="/areas"
              onClick={() => {
                if (location.pathname.includes("/areas")) {
                  return;
                }
                resetAreas();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Localidades</IconText>
              </ItemLinkWrapper>
            </ItemLink>

            <ItemLink
              exact
              to="/locations"
              onClick={() => {
                if (location.pathname.includes("/locations")) {
                  return;
                }
                resetLocations();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Barrios</IconText>
              </ItemLinkWrapper>
            </ItemLink>

            <ItemLink
              exact
              to="/users"
              onClick={() => {
                if (location.pathname.includes("/users")) {
                  return;
                }
                resetUsers();
              }}
            >
              <ItemLinkWrapper>
                <IconText>Administradores</IconText>
              </ItemLinkWrapper>
            </ItemLink>
          </MenuCollapsable>
        </ListGroupItem>
      </ListGroup>
    </IconContext.Provider>
  );
};

export default withRouter(
  connect(
    null,
    {
      resetForms,
      resetMembers,
      resetClicnics,
      resetSpecialties,
      resetMServices,
      resetPharmacies,
      resetLocations,
      resetAreas,
      resetProvinces,
      resetUsers
    }
  )(SidebarNavItem)
);
