import {
  GET_CLINIC_TYPE_LOADING,
  GET_CLINICS_TYPES_SUCCESS,
  GET_CLINICS_TYPES_FAILURE
} from "../actions/types/clinicsTypes";

const initialState = {
  clinicTypes: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLINIC_TYPE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_CLINICS_TYPES_SUCCESS: {
      return {
        ...state,
        clinicTypes: action.payload.clinicsTypes,
        loading: false
      };
    }

    case GET_CLINICS_TYPES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
