import axios from "axios";
import { API_URL } from "../api";
import {
  LOADING_MET,
  GET_MET_SUCCESS,
  GET_MET_FAILURE,
  SET_MET_SEARCH_QUERY,
  SET_MET_PAGE,
  GET_MET_INFO_SUCCESS,
  GET_MET_INFO_FAILURE
} from "./types/medicalEntityTypes";
import { logout } from "./authActions";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";

export const getMET = filters => async dispatch => {
  dispatch({ type: LOADING_MET });
  try {
    const endPoint = `${API_URL}/api/admin/medical_entity_type`;
    const pageParams = `?page=${filters.page}&orderBy=description&orderByDir=asc`;

    let URL = `${endPoint}${pageParams}`;

    if (filters.query.length > 0) {
      const searchParams = `&description=${filters.query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_MET_SUCCESS,
      payload: {
        types: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_MET_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }
  }
};

export const addMET = data => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/medical_entity_type`,
      data
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Tipo de Centro Médico creado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getMETInfo = id => async dispatch => {
  dispatch({ type: LOADING_MET });

  try {
    const response = await axios.get(
      `${API_URL}/api/admin/medical_entity_type/${id}`
    );

    dispatch({
      type: GET_MET_INFO_SUCCESS,
      payload: {
        medicalEntity: response.data.medicalEntityType
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_MET_INFO_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateMET = (id, data) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/medical_entity_type/${id}`,
      data
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Tipo de Centro Médico editado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteMET = id => async dispatch => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/admin/medical_entity_type/${id}`
    );

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Tipo de Centro Médico eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_MET_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_MET_PAGE,
  payload: {
    value: page
  }
});
