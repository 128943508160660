import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { connect } from "react-redux";
import { updateUser } from "../../actions/usersActions";
import { Formik } from "formik";
import * as Yup from "yup";
import PrimaryBtn from "../PrimaryBtn";

const ProfileForm = ({ user, updateUser, id, history }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Campo requerido."),
        email: Yup.string()
          .trim()
          .lowercase()
          .email("Debe ser un correo electrónico válido")
          .required("Campo requerido"),
        phone: Yup.string().required("Requerido.")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const response = await updateUser(id, values);

        if (response !== 200) {
          setSubmitting(false);
          return null;
        }

        setSubmitting(false);
        history.push(`/`);
      }}
      render={({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="name">Nombre:</Label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Ingrese el nombre"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.name && touched.name && "is-invalid"}`}
                />
                {errors.name && touched.name && (
                  <FormFeedback className="d-block">{errors.name}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Ingrese el correo electrónico"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.email && touched.email && "is-invalid"}`}
                />
                {errors.email && touched.email && (
                  <FormFeedback className="d-block">
                    {errors.email}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="phone">Teléfono: </Label>
                <Input
                  id="phone"
                  type="text"
                  placeholder="Ingrese el teléfono"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.phone && touched.phone && "is-invalid"}`}
                />
                {errors.phone && touched.phone && (
                  <FormFeedback className="d-block">
                    {errors.phone}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <PrimaryBtn type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                </PrimaryBtn>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default withRouter(
  connect(
    null,
    {
      updateUser
    }
  )(ProfileForm)
);
