import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import { connect } from "react-redux";
import { getFormTypeInfo } from "../../actions/forms";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import ElementWrapper from "../styles/ElementWrapper";

const FormTypesDetail = ({
  getFormTypeInfo,
  match,
  history,
  loading,
  form
}) => {
  const fetchData = async () => {
    const response = await getFormTypeInfo(match.params.id);

    if (response !== 200) {
      history.push("/form_types");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Tipo de Formulario" loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {form.name}</p>
                </div>
                <div className="my-3">
                  <p className="m-0">Descripción: {form.description}</p>
                </div>
                <div className="my-3">
                  <p className="m-0">
                    Hay que ser afiliado para verlo:{" "}
                    {form.loggedin === 0 ? "No" : "Si"}
                  </p>
                </div>

                <div className="my-3">
                  <p className="m-0 mb-2">Emails: </p>
                  <ListGroup>
                    {form.emails &&
                      form.emails.map((item, index) => (
                        <ListGroupItem key={index}>{item}</ListGroupItem>
                      ))}
                  </ListGroup>
                </div>
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

FormTypesDetail.propTypes = {
  getFormTypeInfo: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  form: state.forms.form,
  loading: state.forms.loading
});

export default connect(mapStateToProps, { getFormTypeInfo })(FormTypesDetail);
