import {
  PROVINCES_LOADING,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_FAILURE,
  SET_PROVINCES_SEARCH_QUERY,
  SET_PROVINCES_PAGE,
  GET_PROVINCE_DETAIL_SUCCESS,
  GET_PROVINCE_DETAIL_FAILURE,
  RESET_PROVINCES_REDUCER
} from "../actions/types/provincesTypes";

const initialState = {
  provinces: [],
  province: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROVINCES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_PROVINCES_SUCCESS: {
      return {
        ...state,
        provinces: action.payload.provinces,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_PROVINCES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_PROVINCE_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        province: {
          ...state.province,
          ...action.payload.province
        }
      };
    }

    case GET_PROVINCE_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_PROVINCES_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_PROVINCES_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_PROVINCES_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
