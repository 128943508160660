import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaAngleLeft, FaPlus } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import PageTitle from "./styles/PageTitle";
import { Row, Col, Spinner } from "reactstrap";

const LinkWrapper = styled.button`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.whiteColor};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 150ms ease-in-out;
  border-radius: 20px;
  padding: 6px 30px;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.whiteColor};
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    outline: 0;
    text-decoration: none;
    border: none;
  }
`;

const PanelNavs = ({
  title = "",
  add = false,
  noBtn = false,
  loading,
  history,
  url
}) => {
  const handleClick = () => {
    if (loading) {
      return;
    }

    if (add) {
      history.push(url);
      return;
    }

    history.goBack();
  };

  return (
    <Row className="align-items-center">
      {!noBtn && (
        <Col className={`${add ? "order-2 text-right" : "text-left"} col-sm-4`}>
          <LinkWrapper onClick={handleClick}>
            {add ? <FaPlus /> : <FaAngleLeft />}
          </LinkWrapper>
        </Col>
      )}

      <Col className={add ? "order-1" : "text-right"}>
        {loading ? (
          <Spinner className="custom-spinner" />
        ) : (
          <PageTitle>{title}</PageTitle>
        )}
      </Col>
    </Row>
  );
};

PanelNavs.propTypes = {
  title: PropTypes.string,
  add: PropTypes.bool,
  noBtn: PropTypes.bool,
  loading: PropTypes.bool
};

export default withRouter(PanelNavs);
