import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";

// Components
import ProvincesItem from "./ProvincesItem";
import ElementWrapper from "../styles/ElementWrapper";

const PharmaciesList = ({ provinces }) => {
  if (provinces.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {provinces.map(province => (
          <ProvincesItem key={province.id} province={province} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

PharmaciesList.propTypes = {
  provinces: PropTypes.array.isRequired
};

export default PharmaciesList;
