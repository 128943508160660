import React from "react";
import { FormGroup, Label, Spinner, FormFeedback } from "reactstrap";
import PlacesAutocomplete from "react-places-autocomplete";

const GooglePlacesField = ({
  label,
  value,
  setFieldValue,
  onSelect,
  errors,
  touched
}) => {
  return (
    <FormGroup>
      <Label for="address">{label}</Label>
      <PlacesAutocomplete
        value={value}
        onChange={address => setFieldValue("address", address)}
        onSelect={place => onSelect(place)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder: "Buscar dirección...",
                className: `form-control ${errors.address &&
                  touched.address &&
                  "is-invalid"}`
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div className="my-1 px-2">
                  <Spinner size="sm" />
                </div>
              )}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
      {errors.address && touched.address && (
        <FormFeedback className="d-block">{errors.address}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default GooglePlacesField;
