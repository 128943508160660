import React from "react";
import { IconContext } from "react-icons";
import config from "../../siteConfig";

const { primaryColor } = config.theme;

const DropdownIcon = ({ children }) => (
  <IconContext.Provider value={{ color: primaryColor }}>
    {children}
  </IconContext.Provider>
);

export default DropdownIcon;
