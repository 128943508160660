import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

const PageMeta = ({ from, to, total, currentPage }) => (
  <Row>
    <Col>
      <small className="text-muted">
        {from || "0"} - {to || "0"} de {total} - Página {currentPage}
      </small>
    </Col>
  </Row>
);

PageMeta.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number
};

export default PageMeta;
