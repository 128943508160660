import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Spinner } from "reactstrap";
import { MdRefresh } from "react-icons/md";

const ReloadButton = styled(Button)`
  border-radius: 50% !important;
  margin-left: ${props => (props.isdownloaded ? 0 : "auto")} !important;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primaryColor} !important;
  border: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 200ms ease;

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
`;

const Reload = ({ handleReload, loading, isDownloaded }) => {
  const handleClick = () => {
    handleReload();
  };

  return (
    <ReloadButton onClick={handleClick} isdownloaded={isDownloaded}>
      {loading ? <Spinner size="sm" color="light" /> : <MdRefresh />}
    </ReloadButton>
  );
};

Reload.propTypes = {
  handleReload: PropTypes.func.isRequired
};

export default Reload;
