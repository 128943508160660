import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import moment from "moment";
import "moment/locale/es";
import * as serviceWorker from "./serviceWorker";
import { setToken } from "./api";
import { store } from "./store";

// Importing CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./lib/virtual-select.min.css";
import "./styles.css";

moment().locale("es");

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// Subscription listeners
store.subscribe(render);
render();

if (localStorage.jwtTokenAS) {
  setToken(localStorage.jwtTokenAS);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
