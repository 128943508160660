import {
  PHARMACY_LOADING,
  GET_ALL_PHARMACIES_SUCCESS,
  GET_ALL_PHARMACIES_FAILURE,
  GET_SINGLE_PHARMACY_SUCCESS,
  GET_SINGLE_PHARMACY_FAILURE,
  DELETE_PHARMACY_SUCCESS,
  DELETE_PHARMACY_FAILURE,
  SET_PHARMACIES_SEARCH_QUERY,
  SET_PAGE,
  RESET_PHARMACIES_REDUCER
} from "../actions/types/pharmaciesTypes";

const initialState = {
  pharmacies: [],
  pharmacy: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PHARMACY_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_ALL_PHARMACIES_SUCCESS: {
      return {
        ...state,
        pharmacies: action.payload.pharmacies,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_ALL_PHARMACIES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SINGLE_PHARMACY_SUCCESS: {
      return {
        ...state,
        pharmacy: action.payload.pharmacy,
        loading: false
      };
    }

    case GET_SINGLE_PHARMACY_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case DELETE_PHARMACY_SUCCESS: {
      return {
        ...state,
        pharmacies: state.pharmacies.filter(
          state => state.id !== action.payload.id
        ),
        loading: false
      };
    }

    case DELETE_PHARMACY_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_PHARMACIES_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_PHARMACIES_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
