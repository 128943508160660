import React from "react";
import PropTypes from "prop-types";
import LoginAnimation from "./LoginAnimation";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Card,
  Form,
  CardBody,
  CardHeader,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Spinner
} from "reactstrap";

import PrimaryBtn from "../PrimaryBtn";
import LoginFormHeader from "./LoginFormHeader";

import { connect } from "react-redux";
import { login } from "../../actions/authActions";

const FormWrapper = styled(Card)`
  border: none !important;
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
`;

const FormHeader = styled(CardHeader)`
  background-color: ${props => props.theme.whiteColor} !important;
  border: none !important;
  text-align: center;
  padding: 2em 1em 1em 1em !important;
  text-transform: uppercase;
`;

class LoginForm extends React.Component {
  componentDidMount() {
    const { isAuthenticated, history } = this.props;

    if (isAuthenticated) {
      history.push("/");
    }
  }

  render() {
    const { login, history } = this.props;

    return (
      <LoginAnimation>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await login(values);

            if (response === 200) {
              history.push("/");
              return;
            }

            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required("El email no puede estar en blanco."),
            password: Yup.string().required(
              "La contraseña no puede estar en blanco"
            )
          })}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <FormWrapper>
              <Form onSubmit={handleSubmit} autoComplete="off">
                <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                  <FormHeader>
                    <LoginFormHeader />
                  </FormHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="text"
                        id="email"
                        placeholder="email@domain.com"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="username email"
                      />
                      {errors.email && touched.email && (
                        <FormFeedback className="d-block">
                          {errors.email}
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Contraseña</Label>
                      <Input
                        type="password"
                        id="password"
                        placeholder="Contraseña"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="current-password"
                      />
                      {errors.password && touched.password && (
                        <FormFeedback className="d-block">
                          {errors.password}
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup className="text-center">
                      <PrimaryBtn type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <Spinner size="sm" />
                        ) : (
                          <span>Login</span>
                        )}
                      </PrimaryBtn>
                    </FormGroup>
                  </CardBody>
                </fieldset>
              </Form>
            </FormWrapper>
          )}
        </Formik>
      </LoginAnimation>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { login }
)(LoginForm);
