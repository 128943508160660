import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner,
  CustomInput
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { addUser } from "../../actions/usersActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";

const UserCreate = ({ addUser, history, loading }) => {
  const [image, setImage] = useState(null);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs
            loading={loading}
            title="Nuevo Usuario Admin"
            backUrl="/users"
          />
        </ContainerHeader>
        <Row className="mb-5">
          <Col>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  name: "",
                  password: "",
                  email: "",
                  phone: "",
                  avatar: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const data = new FormData();

                  if (values.avatar) {
                    data.append("avatar", values.avatar);
                  }

                  data.append("password", values.password);
                  data.append("name", values.name);
                  data.append("email", values.email);
                  data.append("phone", values.phone);

                  const response = await addUser(data);
                  if (response !== 200) {
                    setSubmitting(false);
                    return null;
                  }
                  setSubmitting(false);
                  history.push(`/users`);
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Requerido."),
                  password: Yup.string()
                    .required("Requerido.")
                    .min(
                      8,
                      "La contraseña es demasiado debil, debe contener un minimo de 8 caracteres."
                    ),
                  email: Yup.string()
                    .trim()
                    .lowercase()
                    .email("Debe ser un correo electrónico válido")
                    .required("Requerido"),
                  phone: Yup.string().required("Requerido.")
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                      <FormGroup>
                        <Label for="name">Nombre:</Label>
                        <Input
                          id="name"
                          type="text"
                          placeholder="Ingrese el nombre"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoFocus="autofocus"
                          className={`${errors.name &&
                            touched.name &&
                            "is-invalid"}`}
                        />
                        {errors.name && touched.name && (
                          <FormFeedback className="d-block">
                            {errors.name}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="password">Contraseña:</Label>
                        <Input
                          id="password"
                          type="password"
                          placeholder="Ingrese la contraseña"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.password &&
                            touched.password &&
                            "is-invalid"}`}
                        />
                        {errors.password && touched.password && (
                          <FormFeedback className="d-block">
                            {errors.password}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="email">Email:</Label>
                        <Input
                          id="email"
                          type="email"
                          placeholder="Ingrese el correo electrónico"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.email &&
                            touched.email &&
                            "is-invalid"}`}
                        />
                        {errors.email && touched.email && (
                          <FormFeedback className="d-block">
                            {errors.email}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="phone">Teléfono: </Label>
                        <Input
                          id="phone"
                          type="text"
                          placeholder="Ingrese el teléfono"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.phone &&
                            touched.phone &&
                            "is-invalid"}`}
                        />
                        {errors.phone && touched.phone && (
                          <FormFeedback className="d-block">
                            {errors.phone}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="avatar">Selecciona un archivo.</Label>
                        <CustomInput
                          id="avatar"
                          type="file"
                          label="Selecciona un archivo."
                          onChange={e => {
                            e.preventDefault();
                            let file = e.target.files[0];
                            let previewReader = new FileReader();

                            if (!file) {
                              setImage(null);
                              setFieldValue("avatar", "");
                              return;
                            }

                            previewReader.onloadend = () => {
                              setImage(previewReader.result);
                              setFieldValue("avatar", file);
                            };

                            previewReader.readAsDataURL(file);
                          }}
                        />
                      </FormGroup>

                      {image && (
                        <FormGroup>
                          <img
                            src={image}
                            className="img-fluid d-block image-user-create"
                            alt="preview"
                          />
                        </FormGroup>
                      )}

                      <FormGroup>
                        <PrimaryBtn type="submit" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                        </PrimaryBtn>
                      </FormGroup>
                    </fieldset>
                  </Form>
                )}
              />
            </ElementWrapper>
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

UserCreate.propTypes = {
  addUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: state.users.loading
});

export default connect(
  mapStateToProps,
  { addUser }
)(UserCreate);
