export const LOCATIONS_LOADING = "LOCATIONS_LOADING";

export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAILURE = "GET_LOCATIONS_FAILURE";

export const SET_LOCATIONS_SEARCH_QUERY = "SET_LOCATIONS_SEARCH_QUERY";
export const SET_LOCATIONS_PAGE = "SET_LOCATIONS_PAGE";

export const GET_LOCATION_DETAIL_SUCCESS = "GET_LOCATION_DETAIL_SUCCESS";
export const GET_LOCATION_DETAIL_FAILURE = "GET_LOCATION_DETAIL_FAILURE";

export const RESET_LOCATION_REDUCER = "RESET_LOCATION_REDUCER";
