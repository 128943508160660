import {
  MSERVICES_LOADING,
  GET_MSERVICES_SUCCESS,
  GET_MSERVICES_FAILURE,
  SET_MSERVICES_SEARCH_QUERY,
  SET_MSERVICES_PAGE,
  GET_MSERVICE_DETAIL_SUCCESS,
  GET_MSERVICE_DETAIL_FAILURE,
  RESET_MSERVICES_REDUCER
} from "../actions/types/mservices";

const initialState = {
  services: [],
  service: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MSERVICES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_MSERVICES_SUCCESS: {
      return {
        ...state,
        services: action.payload.services,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_MSERVICES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_MSERVICE_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        service: {
          ...state.service,
          ...action.payload.service
        }
      };
    }

    case GET_MSERVICE_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_MSERVICES_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_MSERVICES_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_MSERVICES_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
