import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Select from "react-select";

import { connect } from "react-redux";
import { addArea } from "../../actions/areas";
import { getProvinces } from "../../actions/provinces";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";
import GooglePlacesField from "../GooglePlacesField";
import Map from "../Map";

const AreasCreate = ({
  addArea,
  history,
  getProvinces,
  provinces,
  loading
}) => {
  const [latLong, setLatLong] = useState({
    lat: 0,
    lng: 0
  });

  const [parsedProvinces, setParsedProvinces] = useState([]);

  const fetchProvinces = async () => {
    await getProvinces(null, true);
  };

  useEffect(() => {
    fetchProvinces();
  }, [provinces.length]);

  useEffect(() => {
    setParsedProvinces(
      provinces.map(province => ({ value: province.id, label: province.name }))
    );
  }, [provinces.length]);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Nueva Localidad" />
        </ContainerHeader>
        <Row className="mb-5">
          <Col sm={6}>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  name: "",
                  address: "",
                  lat: "",
                  lon: "",
                  province_id: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const newValues = {
                    name: values.name,
                    lat: values.lat,
                    lon: values.lon,
                    province_id: values.province_id
                  };
                  const response = await addArea(newValues);
                  if (response !== 200) {
                    setSubmitting(false);
                    return;
                  }
                  setSubmitting(false);
                  history.push(`/areas`);
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Requerido."),
                  address: Yup.string().required("Requerido."),
                  province_id: Yup.string().required("Requerido.")
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }) => {
                  const handleAddressSelect = async place => {
                    setFieldValue("address", place);
                    const results = await geocodeByAddress(place);
                    const { lat, lng } = await getLatLng(results[0]);

                    setLatLong({
                      ...latLong,
                      lat,
                      lng
                    });

                    setFieldValue("lat", lat);
                    setFieldValue("lon", lng);
                  };

                  return (
                    <Form>
                      <fieldset
                        disabled={isSubmitting}
                        aria-busy={isSubmitting}
                      >
                        <FormGroup>
                          <Label for="name">Nombre:</Label>
                          <Input
                            id="name"
                            type="text"
                            placeholder="Ingrese nombre de la Localidad"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.name &&
                              touched.name &&
                              "is-invalid"}`}
                          />
                          {errors.name && touched.name && (
                            <FormFeedback className="d-block">
                              {errors.name}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <GooglePlacesField
                          label="Dirección:"
                          setFieldValue={setFieldValue}
                          value={values.address}
                          onSelect={handleAddressSelect}
                          errors={errors}
                          touched={touched}
                        />

                        <FormGroup>
                          <Label for="province_id">Provincia:</Label>
                          {loading ? (
                            <div className="my-1 px-2">
                              <Spinner size="sm" />
                            </div>
                          ) : (
                            <>
                              <Select
                                id="province_id"
                                value={
                                  parsedProvinces
                                    ? parsedProvinces.find(
                                        province =>
                                          province.value === values.province_id
                                      )
                                    : ""
                                }
                                options={parsedProvinces}
                                onChange={async value => {
                                  const id = value.value;
                                  setFieldValue("province_id", id);
                                }}
                                placeholder="Seleccione la provincia"
                                onBlur={handleBlur}
                                className={
                                  errors.province_id && touched.province_id
                                    ? "select-error"
                                    : ""
                                }
                              />
                              {errors.province_id && touched.province_id && (
                                <FormFeedback className="d-block">
                                  {errors.province_id}
                                </FormFeedback>
                              )}
                            </>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <PrimaryBtn type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                          </PrimaryBtn>
                        </FormGroup>
                      </fieldset>
                    </Form>
                  );
                }}
              />
            </ElementWrapper>
          </Col>
          <Col sm={6}>
            <Map center={latLong} zoom={15} />
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

const mapStateToProps = state => ({
  loading: state.provinces.loading,
  provinces: state.provinces.provinces
});

export default connect(
  mapStateToProps,
  {
    getProvinces,
    addArea
  }
)(AreasCreate);
