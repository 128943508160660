import React from "react";
import PropTypes from "prop-types";

// Components
import PanelListWrapper from "../PanelListWrapper";
import UserListItem from "./UserListItem";
import ElementWrapper from "../styles/ElementWrapper";

const UsersList = ({ users }) => {
  if (users.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          return <UserListItem key={user.id} {...user} />;
        })}
      </tbody>
    </PanelListWrapper>
  );
};

UsersList.propTypes = {
  users: PropTypes.array.isRequired
};

export default UsersList;
