import axios from "axios";
import siteConfig from "../siteConfig";

const API_URL = siteConfig.API_URL;

const setToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export { setToken, API_URL };
