import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { addSpecialty } from "../../actions/specialtiesActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";

const SpecialtyCreate = ({ addSpecialty, history }) => {
  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Nueva Especialidad Médica" />
        </ContainerHeader>
        <Row>
          <Col>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  description: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const response = await addSpecialty(values);

                  if (response !== 200) {
                    setSubmitting(false);
                    return;
                  }

                  setSubmitting(false);
                  history.push(`/specialties`);
                }}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required("Campo requerido.")
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                      <FormGroup>
                        <Label for="description">Nombre:</Label>
                        <Input
                          id="description"
                          type="text"
                          placeholder="Ingrese un nombre"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.description &&
                            touched.description &&
                            "is-invalid"}`}
                        />
                        {errors.description && touched.description && (
                          <FormFeedback className="d-block">
                            {errors.description}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <PrimaryBtn type="submit" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                        </PrimaryBtn>
                      </FormGroup>
                    </fieldset>
                  </Form>
                )}
              />
            </ElementWrapper>
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

SpecialtyCreate.propTypes = {
  addSpecialty: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: state.specialties.loading
});

export default connect(
  mapStateToProps,
  { addSpecialty }
)(SpecialtyCreate);
