import {
  ADDRESS_LOADING,
  GET_SINGLE_ADDRESS_LOCATION_SUCCESS,
  GET_SINGLE_ADDRESS_LOCATION_FAILURE,
  GET_SINGLE_ADDRESS_PROVINCE_SUCCESS,
  GET_SINGLE_ADDRESS_PROVINCE_FAILURE,
  GET_PROVINCIES_LOADING,
  GET_ADDRESS_PROVINCIES_SUCCESS,
  GET_ADDRESS_PROVINCIES_FAILURE,
  GET_LOCATIONS_LOADING,
  GET_ADDRESS_LOCATIONS_SUCCESS,
  GET_ADDRESS_LOCATIONS_FAILURE,
  GET_ADDRESS_AREAS_SUCCESS,
  GET_ADDRESS_AREAS_FAILURE,
  GET_AREAS_LOADING
} from "../actions/types/addressTypes";

const initialState = {
  provincies: {
    data: [],
    loading: false
  },
  locations: {
    data: [],
    loading: false
  },
  single: {
    provinceName: "",
    locationName: ""
  },
  areas: {
    data: [],
    loading: false
  },
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDRESS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_PROVINCIES_LOADING: {
      return {
        ...state,
        provincies: {
          ...state.provincies,
          loading: true
        }
      };
    }

    case GET_LOCATIONS_LOADING: {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: true
        }
      };
    }

    case GET_SINGLE_ADDRESS_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        single: {
          ...state.single,
          locationName: action.payload.locationName
        }
      };
    }

    case GET_SINGLE_ADDRESS_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SINGLE_ADDRESS_PROVINCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        single: {
          ...state.single,
          provinceName: action.payload.provinceName
        }
      };
    }

    case GET_SINGLE_ADDRESS_PROVINCE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_ADDRESS_PROVINCIES_SUCCESS: {
      return {
        ...state,
        provincies: {
          ...state.provincies,
          data: action.payload.provincies,
          loading: false
        }
      };
    }

    case GET_ADDRESS_PROVINCIES_FAILURE: {
      return {
        ...state,
        provincies: {
          ...state.provincies,
          loading: false
        }
      };
    }

    case GET_ADDRESS_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: {
          ...state.locations,
          data: action.payload.locations,
          loading: false
        }
      };
    }

    case GET_ADDRESS_LOCATIONS_FAILURE: {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: false
        }
      };
    }

    case GET_AREAS_LOADING: {
      return {
        ...state,
        areas: {
          ...state.areas,
          loading: true
        }
      };
    }

    case GET_ADDRESS_AREAS_SUCCESS: {
      return {
        ...state,
        areas: {
          ...state.areas,
          loading: false,
          data: [...action.payload.areas]
        }
      };
    }

    case GET_ADDRESS_AREAS_FAILURE: {
      return {
        ...state,
        areas: {
          ...state.areas,
          loading: false
        }
      };
    }

    default: {
      return state;
    }
  }
};
