import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import FormsContainer from "./FormsContainer";
import FormDetail from "./FormDetail";

const FormRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={FormsContainer} />
      <Route path={`${match.path}/:id`} component={FormDetail} />
    </Switch>
  </Fragment>
);

export default FormRoutes;
