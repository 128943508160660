import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";

import ElementWrapper from "../styles/ElementWrapper";
import FormTypesItem from "./FormTypesItem";

const FormTypesList = ({ forms }) => {
  if (forms.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Descripción</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {forms.map(form => (
          <FormTypesItem key={form.id} form={form} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

FormTypesList.propTypes = {
  forms: PropTypes.array.isRequired
};

export default FormTypesList;
