import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import ProvincesContainer from "./ProvincesContainer";
import ProvincesCreate from "./ProvincesCreate";
import ProvincesEdit from "./ProvincesEdit";
import ProvincesDetails from "./ProvincesDetails";

const ProvincesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={ProvincesContainer} />
      <Route path={`${match.path}/add`} component={ProvincesCreate} />
      <Route path={`${match.path}/:id/edit`} component={ProvincesEdit} />
      <Route path={`${match.path}/:id`} component={ProvincesDetails} />
    </Switch>
  </Fragment>
);

export default ProvincesRoutes;
