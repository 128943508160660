import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

// Pages
import PharmaciesContainer from "./PharmaciesContainer";
import PharmacyCreate from "./PharmacyCreate";
import PharmacyUpdate from "./PharmacyUpdate";
import PharmacySingle from "./PharmacySingle";

const PharmaciesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={PharmaciesContainer} />
      <Route path={`${match.path}/add`} component={PharmacyCreate} />
      <Route path={`${match.path}/:id/edit`} component={PharmacyUpdate} />
      <Route path={`${match.path}/:id`} component={PharmacySingle} />
    </Switch>
  </Fragment>
);

export default withRouter(PharmaciesRoutes);
