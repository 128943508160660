import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { deleteUser, getUsers } from "../../actions/usersActions";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const UserListItem = ({
  name,
  email,
  filters,
  id,
  deleteUser,
  getUsers,
  authUserId
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteUser(id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getUsers(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{name}</td>
      <td>{email}</td>
      <td>
        <TableItemActions
          pathName="users"
          id={id}
          noDelete={id === authUserId ? true : false}
          noEdit={true}
          toggle={toggle}
        />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Desea eliminar esta farmacia?"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

UserListItem.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  filters: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.users.filters,
  authUserId: state.auth.user.id
});

export default connect(
  mapStateToProps,
  { deleteUser, getUsers }
)(UserListItem);
