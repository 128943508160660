import React from "react";
import PageWrapper from "../styles/PageWrapper";
import PageTransition from "../styles/PageTransition";
import Logo from "../../images/logo.svg";

const Dashboard = () => {
  return (
    <PageTransition>
      <PageWrapper>
        <img src={Logo} alt="logo" />
      </PageWrapper>
    </PageTransition>
  );
};

export default Dashboard;
