import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { MdSettings, MdExitToApp } from "react-icons/md";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IconContext } from "react-icons";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  NavItem
} from "reactstrap";

// Styled components
import { DropdownOptionBtn } from "../components/styles/DropdownOption";
import DropdownIcon from "../components/styles/DropdownIcon";
import DropdownText from "../components/styles/DropdownText";
import TogglerBtn from "../components/styles/TogglerBtn";

// Redux
import { connect } from "react-redux";
import { setToggler } from "../actions/togglerActions";
import { logout } from "../actions/authActions";

const NavbarWrapper = styled(Navbar)`
  background-color: ${props => props.theme.whiteColor};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
`;

const UserInfoWrapper = styled(DropdownToggle)`
  border: 1px solid ${props => props.theme.transBlack};
  display: flex !important;
  align-items: center;
`;

const UserInfoInner = styled.div`
  display: flex;
  align-items: center;
`;

const UserImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const UserText = styled.p`
  margin: 0;
  color: ${props => props.theme.primaryColor};
  font-weight: 700;
  padding-left: 0.5em;
`;

const NavbarHeader = ({ toggler, setToggler, auth, logout, history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, avatar } = auth.user;
  const { id } = auth.user;

  return (
    <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
      <NavbarWrapper light expand="xs">
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          {!toggler && (
            <Nav className="mr-auto" navbar>
              <NavItem>
                <TogglerBtn onClick={() => setToggler()}>
                  {toggler ? <FiChevronLeft /> : <FiChevronRight />}
                </TogglerBtn>
              </NavItem>
            </Nav>
          )}

          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <UserInfoWrapper nav caret>
                <UserInfoInner>
                  <UserImage
                    src={
                      avatar
                        ? avatar
                        : "https://via.placeholder.com/50?text=AVATAR"
                    }
                    alt={`user ${name}`}
                  />
                  <UserText>{name}</UserText>
                </UserInfoInner>
              </UserInfoWrapper>
              <DropdownMenu right className="p-0">
                <DropdownOptionBtn
                  onClick={() => {
                    history.push(`/profile/${id}`);
                  }}
                >
                  <DropdownIcon>
                    <MdSettings />
                  </DropdownIcon>
                  <DropdownText>Mi cuenta</DropdownText>
                </DropdownOptionBtn>

                <DropdownOptionBtn
                  onClick={() => {
                    history.push("/login");
                    logout();
                  }}
                >
                  <DropdownIcon>
                    <MdExitToApp />
                  </DropdownIcon>
                  <DropdownText>Cerrar sesión</DropdownText>
                </DropdownOptionBtn>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </NavbarWrapper>
    </IconContext.Provider>
  );
};

const mapStateToProps = state => ({
  toggler: state.togglers.togglerSidebar,
  auth: state.auth
});

NavbarHeader.propTypes = {
  setToggler: PropTypes.func.isRequired,
  toggler: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    { setToggler, logout }
  )(NavbarHeader)
);
