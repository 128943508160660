import styled from "styled-components";

const PageWrapper = styled.div`
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PageWrapper;
