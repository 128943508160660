import React from "react";
import PageWrapper from "../components/styles/PageWrapper";
import PageTransition from "../components/styles/PageTransition";

const error = () => {
  return (
    <PageTransition>
      <PageWrapper>
        <h1>Ups, pagina no encontrada.</h1>
      </PageWrapper>
    </PageTransition>
  );
};

export default error;
