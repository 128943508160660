import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import { connect } from "react-redux";
import { getSpecialtyInfo } from "../../actions/specialtiesActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import SpecialtyCollapse from "./SpecialtyCollapse";

const SpecialtySingle = ({
  match,
  getSpecialtyInfo,
  history,
  loading,
  specialty
}) => {
  const fetchData = async () => {
    const response = await getSpecialtyInfo(match.params.id);

    if (response !== 200) {
      history.push("/specialties");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Especialidad Médica" loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col>
              <ElementWrapper className="p-3">
                <p>Descripcion: {specialty.description}</p>
                <p>
                  Centros Médicos:{" "}
                  {specialty.medical_entity && specialty.medical_entity.length}
                </p>

                {specialty.medical_entity &&
                  specialty.medical_entity.length > 0 && (
                    <ListGroup>
                      {specialty.medical_entity.map(clinic => (
                        <ListGroupItem key={clinic.id}>
                          <SpecialtyCollapse {...clinic} />
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

SpecialtySingle.propTypes = {
  specialty: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getSpecialtyInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  specialty: state.specialties.specialty,
  loading: state.specialties.loading
});

export default connect(
  mapStateToProps,
  { getSpecialtyInfo }
)(SpecialtySingle);
