import React, { useState } from "react";
import { Row, Col, Tooltip, Spinner } from "reactstrap";
import Searcher from "./Searcher";
import Reload from "./Reload";
import FormTypesFilter from "./FormTypes";
import { FiDownload } from "react-icons/fi";

const PanelHead = ({
  handleSearch,
  handleReload,
  refe,
  loading,
  formTypeFilter,
  handleFormTypeFilter,
  handleDownloadFile,
  loadingDownload
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Row className="my-4 align-items-center">
      <Col
        xs={12}
        sm={formTypeFilter ? 6 : 8}
        md={formTypeFilter ? 5 : 8}
        lg={formTypeFilter ? 4 : 8}
        xl={formTypeFilter ? 3 : 8}
      >
        <Searcher onSubmit={handleSearch} ref={refe} />
      </Col>
      {formTypeFilter && (
        <Col xs={12} sm={4} md={5} lg={4} xl={4} className="my-2 my-sm-0">
          <FormTypesFilter onChange={handleFormTypeFilter} />
        </Col>
      )}
      <Col
        xs={12}
        sm={formTypeFilter ? 2 : 4}
        md={formTypeFilter ? 2 : 4}
        lg={formTypeFilter ? 4 : 4}
        className={`text-right d-flex align-items-center ${
          handleDownloadFile ? "justify-content-end" : null
        }`}
      >
        {handleDownloadFile && (
          <>
            {loadingDownload ? (
              <div className="my-1 px-4">
                <Spinner style={{ color: "#FC6435" }} />
              </div>
            ) : (
              <>
                <button
                  onClick={() => handleDownloadFile()}
                  id="TooltipExample"
                  style={{
                    fontSize: "2rem",
                    color: "#FC6435"
                  }}
                  className="downloadIcon"
                >
                  <FiDownload />
                </button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Exportar
                </Tooltip>
              </>
            )}
          </>
        )}

        <Reload
          handleReload={handleReload}
          isDownloaded={handleDownloadFile ? 1 : 0}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default PanelHead;
