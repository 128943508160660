import React from "react";
import LogoImage from "../../images/logo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LoginFormHeader = () => {
  return (
    <LazyLoadImage
      alt={`Logo Login`}
      effect="blur"
      width={`120px`}
      src={LogoImage}
      className="img-fluid d-block mx-auto"
    />
  );
};

export default LoginFormHeader;
