import axios from "axios";
import { API_URL } from "../api";
import {
  ADDRESS_LOADING,
  GET_SINGLE_ADDRESS_LOCATION_SUCCESS,
  GET_SINGLE_ADDRESS_LOCATION_FAILURE,
  GET_SINGLE_ADDRESS_PROVINCE_SUCCESS,
  GET_SINGLE_ADDRESS_PROVINCE_FAILURE,
  GET_PROVINCIES_LOADING,
  GET_ADDRESS_PROVINCIES_SUCCESS,
  GET_ADDRESS_PROVINCIES_FAILURE,
  GET_LOCATIONS_LOADING,
  GET_ADDRESS_LOCATIONS_SUCCESS,
  GET_ADDRESS_LOCATIONS_FAILURE,
  GET_ADDRESS_AREAS_SUCCESS,
  GET_ADDRESS_AREAS_FAILURE,
  GET_AREAS_LOADING
} from "./types/addressTypes";
import { SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { orderBy } from "lodash";

export const getProvinceName = provinceId => async dispatch => {
  try {
    dispatch({ type: ADDRESS_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/provincies?id=${provinceId}`
    );

    const provinceName = response.data.data[0].name;

    dispatch({
      type: GET_SINGLE_ADDRESS_PROVINCE_SUCCESS,
      payload: {
        provinceName
      }
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ADDRESS_PROVINCE_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [error.message]
      });
    }

    return error;
  }
};

export const getLocationName = locationId => async dispatch => {
  try {
    dispatch({ type: ADDRESS_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/locations?id=${locationId}`
    );

    const locationName = response.data.data[0].name;

    dispatch({
      type: GET_SINGLE_ADDRESS_LOCATION_SUCCESS,
      payload: {
        locationName
      }
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ADDRESS_LOCATION_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [error.message]
      });
    }

    return error;
  }
};

export const getProvincies = () => async dispatch => {
  dispatch({ type: GET_PROVINCIES_LOADING });
  try {
    const response = await axios.get(
      `${API_URL}/api/admin/provinces?pagination=40`
    );

    dispatch({
      type: GET_ADDRESS_PROVINCIES_SUCCESS,
      payload: {
        provincies: response.data.data.map(provincie => ({
          value: provincie.id,
          label: provincie.name
        }))
      }
    });
  } catch (error) {
    dispatch({
      type: GET_ADDRESS_PROVINCIES_FAILURE
    });
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [error.message]
      });
    }
  }
};

export const getLocationById = id => async dispatch => {
  try {
    dispatch({ type: GET_LOCATIONS_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/locations?&area_id=${id}&pagination=2000`
    );

    const locations = response.data.data.map(location => ({
      value: location.id,
      label: location.name
    }));

    const orderedLocations = orderBy(locations, ["label"], ["asc"]);

    dispatch({
      type: GET_ADDRESS_LOCATIONS_SUCCESS,
      payload: {
        locations: orderedLocations
      }
    });

    return response.data;
  } catch (error) {
    dispatch({ type: GET_ADDRESS_LOCATIONS_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [error.message]
      });
    }
  }
};

export const getAreaById = id => async dispatch => {
  dispatch({ type: GET_AREAS_LOADING });
  try {
    const response = await axios.get(
      `${API_URL}/api/admin/areas?province_id=${id}&pagination=600`
    );

    dispatch({
      type: GET_ADDRESS_AREAS_SUCCESS,
      payload: {
        areas: response.data.data.map(area => ({
          value: area.id,
          label: area.name
        }))
      }
    });
  } catch (error) {
    dispatch({ type: GET_ADDRESS_AREAS_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [error.message]
      });
    }
  }
};

export const getLocationInfoByName = name => async dispatch => {
  const response = await axios.get(`${API_URL}/api/admin/areas?&name=${name}`);

  if (response.data.data && response.data.data.length > 0) {
    return response.data.data[0].province.id;
  }

  return;
};
