import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import VirtualizedSelect from "react-virtualized-select";

import { connect } from "react-redux";
import { getLocation, updateLocation } from "../../actions/locations";
import { getProvinces } from "../../actions/provinces";
import { getAreas } from "../../actions/areas";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";
import LoaderWrapper from "../styles/LoaderWrapper";
import Loader from "../Loader";
import GooglePlacesField from "../GooglePlacesField";
import Map from "../Map";

const LocationsEdit = ({
  loading,
  getLocation,
  history,
  match,
  location,
  getProvinces,
  provinces,
  loadingProvinces,
  updateLocation,
  getAreas,
  areas,
  loadingAreas
}) => {
  const [latLong, setLatLong] = useState({
    lat: 0,
    lng: 0
  });

  const [parsedProvinces, setParsedProvinces] = useState([]);
  const [parsedAreas, setParsedAreas] = useState([]);

  const fetchData = async () => {
    const response = await getLocation(match.params.id);

    await Promise.all([getProvinces(null, true), getAreas(null, true)]);

    if (response !== 200) {
      history.push("/locations");
      return;
    }
  };

  useEffect(() => {
    fetchData();

    setLatLong({
      ...latLong,
      lat: location.lat,
      lng: location.lon
    });
  }, [location.id]);

  useEffect(() => {
    setParsedProvinces(
      provinces.map(province => ({ value: province.id, label: province.name }))
    );
  }, [provinces.length]);

  useEffect(() => {
    setParsedAreas(areas.map(area => ({ value: area.id, label: area.name })));
  }, [areas.length]);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs loading={loading} title="Editar Barrio" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5 align-items-center locations-content">
            <Col sm={6}>
              <ElementWrapper className="p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: location.name || "",
                    address: location.name || "",
                    lat: location.lat || "",
                    lon: location.lon || "",
                    province_id:
                      (location.area && location.area.province.id) || "",
                    area_id: location.area ? location.area.id : ""
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const newValues = {
                      name: values.name,
                      lat: values.lat,
                      lon: values.lon,
                      country_id: values.country_id,
                      area_id: values.area_id
                    };

                    const response = await updateLocation(
                      match.params.id,
                      newValues
                    );

                    if (response !== 200) {
                      setSubmitting(false);
                      return;
                    }

                    setSubmitting(false);
                    history.push(`/locations`);
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Requerido."),
                    address: Yup.string().required("Requerido."),
                    area_id: Yup.string()
                      .required("Requerido.")
                      .nullable()
                  })}
                  render={({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    setFieldValue
                  }) => {
                    const handleAddressSelect = async place => {
                      setFieldValue("address", place);
                      const results = await geocodeByAddress(place);
                      const { lat, lng } = await getLatLng(results[0]);

                      setLatLong({
                        ...latLong,
                        lat,
                        lng
                      });

                      setFieldValue("lat", lat);
                      setFieldValue("lon", lng);
                    };

                    return (
                      <Form>
                        <fieldset
                          disabled={isSubmitting}
                          aria-busy={isSubmitting}
                        >
                          <FormGroup>
                            <Label for="name">Nombre:</Label>
                            <Input
                              id="name"
                              type="text"
                              placeholder="Ingrese nombre de la provincia"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.name &&
                                touched.name &&
                                "is-invalid"}`}
                            />
                            {errors.name && touched.name && (
                              <FormFeedback className="d-block">
                                {errors.name}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <GooglePlacesField
                            loading={loading}
                            label="Dirección:"
                            setFieldValue={setFieldValue}
                            value={values.address}
                            onSelect={handleAddressSelect}
                            errors={errors}
                            touched={touched}
                          />

                          <FormGroup>
                            <Label for="province_id">Provincia:</Label>

                            <VirtualizedSelect
                              id="province_id"
                              value={
                                parsedProvinces
                                  ? parsedProvinces.find(
                                      province =>
                                        province.value === values.province_id
                                    )
                                  : ""
                              }
                              options={parsedProvinces}
                              onChange={async value => {
                                const id = value.value;
                                setFieldValue("province_id", id);
                              }}
                              placeholder="Seleccione la provincia"
                              onBlur={handleBlur}
                              className={
                                errors.province_id && touched.province_id
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={loadingProvinces}
                            />
                            {errors.province_id && touched.province_id && (
                              <FormFeedback className="d-block">
                                {errors.province_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="area_id">Localidad:</Label>
                            <VirtualizedSelect
                              id="area_id"
                              value={
                                parsedAreas
                                  ? parsedAreas.find(
                                      area => area.value === values.area_id
                                    )
                                  : ""
                              }
                              options={parsedAreas}
                              onChange={async value => {
                                const id = value && value.value;
                                setFieldValue("area_id", id);
                              }}
                              placeholder="Seleccione la localidad"
                              onBlur={handleBlur}
                              className={
                                errors.area_id && touched.area_id
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={loadingAreas}
                            />
                            {errors.area_id && touched.area_id && (
                              <FormFeedback className="d-block">
                                {errors.area_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <PrimaryBtn type="submit" disabled={isSubmitting}>
                              {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                            </PrimaryBtn>
                          </FormGroup>
                        </fieldset>
                      </Form>
                    );
                  }}
                />
              </ElementWrapper>
            </Col>
            <Col sm={6}>
              <Map center={latLong} zoom={15} />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

const mapStateToprops = state => ({
  loading: state.locations.loading,
  location: state.locations.location,
  provinces: state.provinces.provinces,
  loadingProvinces: state.provinces.loading,
  areas: state.areas.areas,
  loadingAreas: state.areas.loading
});

export default connect(mapStateToprops, {
  getLocation,
  getProvinces,
  updateLocation,
  getAreas
})(LocationsEdit);
