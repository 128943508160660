import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getAreaById } from "../../actions/areas";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import Map from "../Map";

const AreasDetail = ({ getAreaById, match, history, loading, area }) => {
  const fetchData = async () => {
    const response = await getAreaById(match.params.id);

    if (response !== 200) {
      history.push("/areas");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Localidad" loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {area.name}</p>
                </div>
                <div className="my-3">
                  <p className="m-0">
                    Provincia: {area.province && area.province.name}
                  </p>
                </div>
              </ElementWrapper>
            </Col>
            <Col sm={6}>
              <Map
                center={{ lat: area.lat, lng: area.lon }}
                zoom={15}
                height={500}
              />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

AreasDetail.propTypes = {
  getAreaById: PropTypes.func.isRequired,
  area: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  area: state.areas.area,
  loading: state.areas.loading
});

export default connect(
  mapStateToProps,
  { getAreaById }
)(AreasDetail);
