import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider as StyledProvider } from "styled-components";
import config from "./siteConfig";

// Context for handle toggler effects.
import GlobalStyles from "./lib/GlobalStyles";
import GlobalAlert from "./components/GlobalAlert";

// Pages
import Login from "./pages/Login";
import Panel from "./pages/Panel";

// REDUX
import { store, persistor } from "./store";
import { Provider } from "react-redux";

import dotenv from "dotenv";
dotenv.config();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <StyledProvider theme={config.theme}>
            <Fragment>
              <GlobalStyles />
              <GlobalAlert />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={Panel} />
              </Switch>
            </Fragment>
          </StyledProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
