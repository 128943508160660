import React from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { addMService } from "../../actions/mservices";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";

const MServicesCreate = ({ addMService, history }) => {
  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Nuevo Servicio Médico" />
        </ContainerHeader>
        <Row className="mb-5">
          <Col sm={6}>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  description: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const response = await addMService(values);
                  if (response !== 200) {
                    setSubmitting(false);
                    return;
                  }
                  setSubmitting(false);
                  history.push(`/medical_services`);
                }}
                validationSchema={Yup.object().shape({
                  description: Yup.string().required("Requerido.")
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur
                }) => {
                  return (
                    <Form>
                      <fieldset
                        disabled={isSubmitting}
                        aria-busy={isSubmitting}
                      >
                        <FormGroup>
                          <Label for="description">Nombre:</Label>
                          <Input
                            id="description"
                            type="text"
                            placeholder="Ingrese un nombre"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.description &&
                              touched.description &&
                              "is-invalid"}`}
                          />
                          {errors.description && touched.description && (
                            <FormFeedback className="d-block">
                              {errors.description}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <PrimaryBtn type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                          </PrimaryBtn>
                        </FormGroup>
                      </fieldset>
                    </Form>
                  );
                }}
              />
            </ElementWrapper>
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

export default connect(
  null,
  {
    addMService
  }
)(MServicesCreate);
