import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getPharmacies,
  setSearchQuery,
  setPage
} from "../../actions/pharmacieActions";

import { downloadFile } from "../../actions/common";

import PharmaciesList from "./PharmaciesList";
import PageTransition from "../styles/PageTransition";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import Paginator from "../Paginator";
import PageMeta from "../PageMeta";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import PanelHead from "../PanelHead";

import { saveAs } from "file-saver";
import b64toBlob from "../../lib/base64ToXLS";

class PharmaciesContainer extends Component {
  searchField = createRef();

  state = {
    downloadLoading: false
  };

  handleSubmitData = async () => {
    const { getPharmacies, filters } = this.props;
    await getPharmacies(filters);
  };

  async componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  handleDownloadFile = async () => {
    this.setState({ downloadLoading: true });

    const response = await this.props.downloadFile("Farmacias");

    if (response.status === 200) {
      const blob = b64toBlob(response.data.file.content);

      // This function download the file
      saveAs(
        blob,
        `Farmacias_${Math.floor(100000000 + Math.random() * 900000000)}.xls`
      );
    }

    this.setState({ downloadLoading: false });
  };

  render() {
    const {
      pharmacies,
      loading,
      filters,
      first,
      last,
      next,
      prev,
      meta
    } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs title="Farmacias" add={true} url="/pharmacies/add" />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                handleDownloadFile={this.handleDownloadFile}
                loadingDownload={this.state.downloadLoading}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <PharmaciesList pharmacies={pharmacies} />
            )}

            {pharmacies.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={first}
                last={last}
                next={next}
                prev={prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

PharmaciesContainer.propTypes = {
  pharmacies: PropTypes.array.isRequired,
  getPharmacies: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  first: PropTypes.string,
  last: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  meta: PropTypes.object.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  pharmacies: state.pharmacies.pharmacies,
  loading: state.pharmacies.loading,
  first: state.pharmacies.links.first,
  last: state.pharmacies.links.last,
  next: state.pharmacies.links.next,
  prev: state.pharmacies.links.prev,
  meta: state.pharmacies.meta,
  filters: state.pharmacies.filters
});

export default connect(mapStateToProps, {
  getPharmacies,
  setSearchQuery,
  setPage,
  downloadFile
})(PharmaciesContainer);
