import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import METItem from "./METItem";

const METList = ({ data }) => {
  if (data.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <METItem key={item.id} {...item} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

METList.propTypes = {
  data: PropTypes.array.isRequired
};

export default METList;
