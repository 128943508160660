import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getLocation } from "../../actions/locations";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import Map from "../Map";
import ElementWrapper from "../styles/ElementWrapper";

const LocationsDetails = ({
  getLocation,
  match,
  history,
  loading,
  location
}) => {
  const fetchData = async () => {
    const response = await getLocation(match.params.id);

    if (response !== 200) {
      history.push("/locations");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de barrio" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {location.name}</p>
                </div>
                <div className="my-3">
                  <p className="m-0">
                    Provincia: {location.area && location.area.province.name}
                  </p>
                </div>
                <div className="my-3">
                  <p className="m-0">
                    Area: {location.area && location.area.name}
                  </p>
                </div>
              </ElementWrapper>
            </Col>
            <Col sm={6}>
              <Map
                center={{ lat: location.lat, lng: location.lon }}
                zoom={15}
                height={500}
              />
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

LocationsDetails.propTypes = {
  getLocation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  location: state.locations.location,
  loading: state.locations.loading
});

export default connect(
  mapStateToProps,
  { getLocation }
)(LocationsDetails);
