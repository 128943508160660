import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import AreaContainer from "./AreaContainer";
import AreasCreate from "./AreasCreate";
import AreasEdit from "./AreasEdit";
import AreasDetail from "./AreasDetail";

const FormTypesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={AreaContainer} />
      <Route path={`${match.path}/add`} component={AreasCreate} />
      <Route path={`${match.path}/:id/edit`} component={AreasEdit} />
      <Route path={`${match.path}/:id`} component={AreasDetail} />
    </Switch>
  </Fragment>
);

export default FormTypesRoutes;
