import styled from "styled-components";

const DropdownText = styled.p`
  padding-left: 0.5em;
  color: ${props => props.theme.grayColor};
  font-weight: 300;
  margin: 0;
`;

export default DropdownText;
