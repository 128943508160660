import axios from "axios";
import { API_URL } from "../api";
import {
  FORMS_LOADING,
  GET_FORMS_TYPES_SUCCESS,
  GET_FORMS_TYPES_FAILURE,
  SET_FORM_TYPES_SEARCH_QUERY,
  SET_FORM_TYPES_PAGE,
  GET_FORMS_TYPE_INFO_SUCCESS,
  GET_FORMS_TYPE_INFO_FAILURE,
  GET_FORMS_SUCCESS,
  GET_FORMS_FAILURE,
  RESET_FORM_REDUCER,
  GET_FORM_INFO_SUCCESS,
  GET_FORM_INFO_FAILURE
} from "./types/forms";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";

export const getFormsTypes = filters => async dispatch => {
  const { page, query } = filters;

  try {
    dispatch({ type: FORMS_LOADING });

    const endPoint = `${API_URL}/api/admin/contact_form_type`;
    const pageParams = `?page=${page}`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_FORMS_TYPES_SUCCESS,
      payload: {
        forms: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_FORMS_TYPES_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getFormTypeInfo = id => async dispatch => {
  dispatch({ type: FORMS_LOADING });

  try {
    const response = await axios.get(
      `${API_URL}/api/admin/contact_form_type/${id}`
    );

    dispatch({
      type: GET_FORMS_TYPE_INFO_SUCCESS,
      payload: {
        contactFormType: {
          name: response.data.contactFormType.name,
          id: response.data.contactFormType.id,
          loggedin: response.data.contactFormType.loggedin,
          description: response.data.contactFormType.description,
          emails: response.data.contactFormType.emails.map(item => item.email)
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_FORMS_TYPE_INFO_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addFormType = form => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/contact_form_type`,
      form
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Tipo de Formulario de contacto creado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateFormType = (id, values) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/contact_form_type/${id}`,
      values
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Tipo de Formulario de contacto editado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteFormType = id => async dispatch => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/admin/contact_form_type/${id}`
    );

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [
          `Tipo de Formulario de contacto eliminado satisfactoriamente.`
        ]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getForms = filters => async dispatch => {
  dispatch({ type: FORMS_LOADING });

  try {
    const endPoint = `${API_URL}/api/admin/contact_form`;
    const pageParams = `?page=${filters.page}`;

    let URL = `${endPoint}${pageParams}`;

    if (filters.query.length > 0) {
      const searchParams = `&name=${filters.query}`;
      URL = `${URL}${searchParams}`;
    }

    if (filters.formTypeId) {
      const formTypeIdParams = `&contact_form_type_id=${filters.formTypeId}`;
      URL = `${URL}${formTypeIdParams}`;
    }

    const response = await axios.get(URL);

    const forms = response.data.data.sort(function(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    dispatch({
      type: GET_FORMS_SUCCESS,
      payload: {
        forms,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_FORMS_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getFormInfo = id => async dispatch => {
  try {
    dispatch({ type: FORMS_LOADING });

    const response = await axios.get(`${API_URL}/api/admin/contact_form/${id}`);

    dispatch({
      type: GET_FORM_INFO_SUCCESS,
      payload: {
        form: response.data.contactForm
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_FORM_INFO_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_FORM_TYPES_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_FORM_TYPES_PAGE,
  payload: {
    value: page
  }
});

export const resetForms = () => ({
  type: RESET_FORM_REDUCER
});

export const setFormType = id => ({
  type: "SET_FORM_TYPE_ID",
  payload: {
    value: id
  }
});
