import axios from "axios";
import { API_URL } from "../api";
import {
  CLINICS_LOADING,
  GET_ALL_CLINICS_SUCCESS,
  GET_ALL_CLINICS_FAILURE,
  DELETE_CLINIC_SUCCESS,
  DELETE_CLINIC_FAILURE,
  GET_SINGLE_CLINIC_SUCCESS,
  GET_SINGLE_CLINIC_FAILURE,
  GET_SPECIALTIES_FOR_CLINICS_SUCCESS,
  GET_SPECIALTIES_FOR_CLINICS_FAILURE,
  SPECIALTIES_CLINICS_LOADING,
  GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS,
  GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE,
  SET_CLINICS_SEARCH_QUERY,
  SET_CLINICS_PAGE,
  RESET_CLINICS_REDUCER
} from "./types/clinicsTypes";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";
import AlphabeticOrder from "../lib/AlphabeticOrder";

export const getClinics = filters => async dispatch => {
  const { page, query } = filters;

  try {
    dispatch({ type: CLINICS_LOADING });

    const endPoint = `${API_URL}/api/admin/medical_entity`;
    const pageParams = `?page=${page}&orderBy=name&orderByDir=asc`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_ALL_CLINICS_SUCCESS,
      payload: {
        clinics: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CLINICS_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return "error";
  }
};

export const addClinic = clinic => async dispatch => {
  let response;

  const clinicSpecialties = clinic.specialties.map(
    specialty => specialty.value
  );

  const medicalSpecialties = clinic.medical_service_ids.map(item => item.value);

  const newClinic = {
    name: clinic.name,
    address: clinic.address,
    location_id: clinic.location_id,
    province_id: clinic.province_id,
    medical_entity_type_id: clinic.medical_entity_type_id,
    phone: clinic.phone,
    postcode: clinic.postcode,
    lat: clinic.lat,
    lon: clinic.lon,
    medical_specialty_ids: clinicSpecialties,
    medical_service_ids: medicalSpecialties,
    area_id: clinic.area_id,
    address_extra: clinic.address_extra
  };

  try {
    response = await axios.post(
      `${API_URL}/api/admin/medical_entity`,
      newClinic
    );
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }

  try {
    const clinicId = response.data.medicalEntity.id;

    const medicalSpecialtyEntity = {
      medical_entity_id: clinicId,
      medical_specialties_id: clinicSpecialties
    };

    const medicalServicesEntity = {
      medical_entity_id: clinicId,
      medical_service_ids: medicalSpecialties
    };

    const clinicResponse = await axios.post(
      `${API_URL}/api/admin/medical_entity_specialty`,
      medicalSpecialtyEntity
    );

    const clinicResponse2 = await axios.post(
      `${API_URL}/api/admin/medical_entity_service`,
      medicalServicesEntity
    );

    if (clinicResponse.status === 200 && clinicResponse2.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Clinica, ${clinic.name} fue agregada satisfactoriamente.`]
      });
    }

    return clinicResponse.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateClinic = (id, clinic) => async dispatch => {
  let response;

  const clinicSpecialties = clinic.specialties.map(
    specialty => specialty.value
  );

  const medicalSpecialties = clinic.medical_service_ids.map(item => item.value);

  const updatedClinic = {
    name: clinic.name,
    address: clinic.address,
    location_id: clinic.location_id,
    province_id: clinic.province_id,
    medical_entity_type_id: clinic.medical_entity_type_id,
    phone: clinic.phone,
    postcode: clinic.postcode,
    lat: clinic.lat,
    lon: clinic.lon,
    area_id: clinic.area_id,
    medical_specialty_ids: clinicSpecialties,
    medical_service_ids: medicalSpecialties,
    address_extra: clinic.address_extra
  };

  try {
    response = await axios.put(
      `${API_URL}/api/admin/medical_entity/${id}`,
      updatedClinic
    );
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }

  try {
    const clinicId = response.data.medicalEntity.id;

    const medicalSpecialtyEntity = {
      medical_entity_id: clinicId,
      medical_specialties_id: clinicSpecialties
    };

    const medicalServicesEntity = {
      medical_entity_id: clinicId,
      medical_service_ids: medicalSpecialties
    };

    const clinicResponse = await axios.post(
      `${API_URL}/api/admin/medical_entity_specialty`,
      medicalSpecialtyEntity
    );

    const clinicResponse2 = await axios.post(
      `${API_URL}/api/admin/medical_entity_service`,
      medicalServicesEntity
    );

    if (clinicResponse.status === 200 && clinicResponse2.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Clinica, ${clinic.name} fue editada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteClinic = id => async dispatch => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/admin/medical_entity/${id}`
    );

    if (response.status === 204) {
      dispatch({
        type: DELETE_CLINIC_SUCCESS,
        payload: {
          id
        }
      });

      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Registro eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    dispatch({
      type: DELETE_CLINIC_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getClinicInfo = id => async dispatch => {
  try {
    dispatch({ type: CLINICS_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/medical_entity/${id}`
    );

    let clinic = response.data.medicalEntity;

    clinic = {
      ...clinic,
      medical_specialties: clinic.medical_specialties.map(item => ({
        value: item.id,
        label: item.description
      })),
      medical_services: clinic.medical_services.map(item => ({
        value: item.id,
        label: item.description
      }))
    };

    dispatch({
      type: GET_SINGLE_CLINIC_SUCCESS,
      payload: {
        clinic
      }
    });

    return response.status;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_CLINIC_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getSpecialtiesForClinics = () => async dispatch => {
  try {
    dispatch({ type: SPECIALTIES_CLINICS_LOADING });

    const response = await axios.get(
      `${API_URL}/api/admin/medical_specialty?pagination=200`
    );

    dispatch({
      type: GET_SPECIALTIES_FOR_CLINICS_SUCCESS,
      payload: {
        specialties: AlphabeticOrder(response.data.data, "description").map(
          specialty => ({
            value: specialty.id,
            label: specialty.description
          })
        )
      }
    });

    return response.status;
  } catch (error) {
    dispatch({
      type: GET_SPECIALTIES_FOR_CLINICS_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getSpecialtiesForClinicById = id => async dispatch => {
  dispatch({ type: SPECIALTIES_CLINICS_LOADING });

  try {
    const response = await axios.get(
      `${API_URL}/api/admin/medical_entity/${id}/medical_specialty?pagination=500`
    );

    const medicalSpecialties = response.data.map(specialty => ({
      value: specialty.id,
      label: specialty.description
    }));

    dispatch({
      type: GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS,
      payload: {
        medicalSpecialties
      }
    });
  } catch (error) {
    dispatch({
      type: GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Error al cargar las especialidades de esta clinica."]
      });
    }

    return "error";
  }
};

export const setSearchQuery = query => ({
  type: SET_CLINICS_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_CLINICS_PAGE,
  payload: {
    value: page
  }
});

export const resetClicnics = () => ({
  type: RESET_CLINICS_REDUCER
});
