import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const FormsItem = ({ form }) => {
  return (
    <TableRow>
      <td>{moment(form.created_at).format("LLL")}</td>
      <td>{form.contact_form_type ? form.contact_form_type.name : "-"}</td>
      <td>{form.name}</td>
      <td>{form.email}</td>
      <td>{form.phone}</td>
      <td>
        <TableItemActions
          pathName="forms"
          id={form.id}
          noDelete={true}
          noEdit={true}
        />
      </td>
    </TableRow>
  );
};

FormsItem.propTypes = {
  form: PropTypes.object.isRequired
};

export default FormsItem;
