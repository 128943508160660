export const SPECIALTIES_LOADING = "SPECIALTIES_LOADING";

export const GET_ALL_SPECIALTIES_SUCCESS = "GET_ALL_SPECIALTIES_SUCCESS";
export const GET_ALL_SPECIALTIES_FAILURE = "GET_ALL_SPECIALTIES_FAILURE";

export const GET_SPECIALTY_SUCCESS = "GET_SPECIALTY_SUCCESS";
export const GET_SPECIALTY_FAILURE = "GET_SPECIALTY_FAILURE";

export const SET_SPECIALTIES_SEARCH_QUERY = "SET_SPECIALTIES_SEARCH_QUERY";
export const SET_SPECIALTIES_PAGE = "SET_SPECIALTIES_PAGE";

export const RESET_SPECIALTIES_REDUCER = "RESET_SPECIALTIES_REDUCER";
