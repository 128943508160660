import styled from "styled-components";
import { Button } from "reactstrap";

const PrimaryBtn = styled(Button)`
  background-color: ${props => props.theme.primaryColor} !important;
  color: ${props => props.theme.whiteColor} !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  transition: all 150ms ease-in-out !important;
  border-radius: 20px !important;
  padding: 10px 30px !important;
  border: none !important;

  &:hover {
    color: ${props => props.theme.whiteColor} !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
    outline: 0 !important;
    text-decoration: none !important;
  }
`;

export default PrimaryBtn;
