import React, { Fragment } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// Components
import Sidebar from "./Sidebar";
import NavbarHeader from "./NavbarHeader";

const AppWrapper = styled.main`
  display: flex;
`;

const MainPanel = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const MainWrapper = styled.div`
  min-height: calc(100vh - 66px);
`;

const PanelLayout = ({ children }) => {
  return (
    <Fragment>
      <AppWrapper>
        <Sidebar />
        <MainPanel>
          <NavbarHeader />
          <MainWrapper className="position-relative">{children}</MainWrapper>
        </MainPanel>
      </AppWrapper>
    </Fragment>
  );
};

export default withRouter(PanelLayout);
