import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { connect } from "react-redux";
import { updateUser } from "../../actions/usersActions";
import { Formik } from "formik";
import * as Yup from "yup";
import PrimaryBtn from "../PrimaryBtn";

const AccountForm = ({ updateUser, id, history }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        password: "",
        repeatPassword: ""
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().trim(),
        repeatPassword: Yup.string().when("password", {
          is: password => password,
          then: Yup.string()
            .oneOf([Yup.ref("password")], "Passwords do not match")
            .required("Required")
        })
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let updatedUserInfo = {
          password: ""
        };

        if (values.password.length > 0) {
          updatedUserInfo = {
            ...updatedUserInfo,
            password: values.password
          };
        }

        const response = await updateUser(id, updatedUserInfo);

        if (response !== 200) {
          setSubmitting(false);
          return null;
        }

        setSubmitting(false);
        history.push(`/`);
      }}
      render={({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="password">Nueva contraseña:</Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Ingrese la contraseña"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <FormGroup>
                <Label for="repeatPassword">Repita la contraseña:</Label>
                <Input
                  id="repeatPassword"
                  type="password"
                  placeholder="Repita la contraseña"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.repeatPassword &&
                    touched.repeatPassword &&
                    "is-invalid"}`}
                />
                {errors.repeatPassword && touched.repeatPassword && (
                  <FormFeedback className="d-block">
                    {errors.repeatPassword}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <PrimaryBtn type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                </PrimaryBtn>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

AccountForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default withRouter(
  connect(
    null,
    {
      updateUser
    }
  )(AccountForm)
);
