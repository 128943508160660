import React, { useState } from "react";
import { DropdownMenu, ButtonDropdown } from "reactstrap";
import { FaEllipsisH } from "react-icons/fa";
import { MdRemoveRedEye, MdModeEdit, MdDelete } from "react-icons/md";
import { DropdownOptionLink, DropdownOptionBtn } from "./styles/DropdownOption";
import DropdownIcon from "./styles/DropdownIcon";
import DropdownText from "./styles/DropdownText";
import ActionBtn from "./styles/ActionBtn";

const TableItemActions = ({
  pathName,
  toggle,
  id,
  noDelete = false,
  noEdit = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ActionBtn>
        <FaEllipsisH />
      </ActionBtn>
      <DropdownMenu className="p-0">
        <DropdownOptionLink to={`/${pathName}/${id}`}>
          <DropdownIcon>
            <MdRemoveRedEye />
          </DropdownIcon>
          <DropdownText>Ver</DropdownText>
        </DropdownOptionLink>

        {!noEdit && (
          <DropdownOptionLink to={`/${pathName}/${id}/edit`}>
            <DropdownIcon>
              <MdModeEdit />
            </DropdownIcon>
            <DropdownText>Editar</DropdownText>
          </DropdownOptionLink>
        )}

        {!noDelete && (
          <DropdownOptionBtn onClick={toggle}>
            <DropdownIcon>
              <MdDelete />
            </DropdownIcon>
            <DropdownText>Eliminar</DropdownText>
          </DropdownOptionBtn>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default TableItemActions;
