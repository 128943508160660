import {
  USERS_LOADING,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  UPDATE_IMAGE_LOADING,
  DELETE_IMAGE_COMPLETE_SUCCESS,
  DELETE_IMAGE_COMPLETE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SET_USERS_SEARCH_QUERY,
  SET_USERS_PAGE,
  RESET_USERS_REDUCER
} from "../actions/types/usersTypes";

const initialState = {
  users: [],
  user: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  uploadImageLoading: false,
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case UPDATE_IMAGE_LOADING: {
      return {
        ...state,
        uploadImageLoading: true
      };
    }

    case UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        uploadImageLoading: false,
        user: {
          ...state.user,
          avatar: action.payload.avatar
        }
      };
    }

    case UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        uploadImageLoading: false
      };
    }

    case DELETE_IMAGE_COMPLETE_SUCCESS: {
      return {
        ...state,
        uploadImageLoading: false,
        user: {
          ...state.user,
          avatar: null
        }
      };
    }

    case DELETE_IMAGE_COMPLETE_FAILURE: {
      return {
        ...state,
        uploadImageLoading: false
      };
    }

    case GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.users,
        loading: false,
        links: {
          ...state.links,
          currentPage: action.payload.links.currentPage,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SINGLE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        loading: false
      };
    }

    case GET_SINGLE_USER_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case ADD_USER_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_USERS_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_USERS_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_USERS_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
