import axios from "axios";
import { API_URL } from "../api";
import {
  PROVINCES_LOADING,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_FAILURE,
  SET_PROVINCES_SEARCH_QUERY,
  SET_PROVINCES_PAGE,
  GET_PROVINCE_DETAIL_SUCCESS,
  GET_PROVINCE_DETAIL_FAILURE,
  RESET_PROVINCES_REDUCER
} from "./types/provincesTypes";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";

export const getProvinces = (
  filters,
  allProvinces = false
) => async dispatch => {
  try {
    dispatch({ type: PROVINCES_LOADING });

    const endPoint = `${API_URL}/api/admin/provinces`;
    const pageParams = `?page=${filters && filters.page}`;

    let URL = `${endPoint}${!allProvinces ? pageParams : "?pagination=40"}`;

    if (filters && filters.query.length > 0) {
      const searchParams = `&name=${filters.query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_PROVINCES_SUCCESS,
      payload: {
        provinces: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_PROVINCES_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getProvince = id => async dispatch => {
  try {
    dispatch({ type: PROVINCES_LOADING });

    const response = await axios.get(`${API_URL}/api/admin/provinces/${id}`);

    dispatch({
      type: GET_PROVINCE_DETAIL_SUCCESS,
      payload: {
        province: response.data.province
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_PROVINCE_DETAIL_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addProvince = province => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/provinces`,
      province
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Provincia creada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateProvince = (id, province) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/provinces/${id}`,
      province
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Provincia ${province.name} fue editada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteProvince = id => async dispatch => {
  try {
    const response = await axios.delete(`${API_URL}/api/admin/provinces/${id}`);

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Provincia eliminada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_PROVINCES_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_PROVINCES_PAGE,
  payload: {
    value: page
  }
});

export const resetProvinces = () => ({
  type: RESET_PROVINCES_REDUCER
});
