import React, { Component, createRef } from "react";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import { getMET, setSearchQuery, setPage } from "../../actions/medicalEntity";

import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PanelHead from "../PanelHead";
import PageMeta from "../PageMeta";
import LoaderWrapper from "../styles/LoaderWrapper";
import Loader from "../Loader";
import Paginator from "../Paginator";
import METList from "./METList";

class METContainer extends Component {
  searchField = createRef();

  handleSubmitData = async () => {
    await this.props.getMET(this.props.filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  render() {
    const { loading, filters, meta, data, links } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs
                title="Tipos de centros médicos"
                add={true}
                url="/medical_entity_type/add"
              />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                loading={loading}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <METList data={data} />
            )}

            {data.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={links.first}
                last={links.last}
                next={links.next}
                prev={links.prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.medicalEntityTypes.loading,
  filters: state.medicalEntityTypes.filters,
  data: state.medicalEntityTypes.types,
  meta: state.medicalEntityTypes.meta,
  links: state.medicalEntityTypes.links
});

export default connect(
  mapStateToProps,
  {
    getMET,
    setSearchQuery,
    setPage
  }
)(METContainer);
