export const MEMBERS_LOADING = "MEMBERS_LOADING";

export const GET_ALL_MEMBERS_SUCCESS = "GET_ALL_MEMBERS_SUCCESS";
export const GET_ALL_MEMBERS_FAILURE = "GET_ALL_MEMBERS_FAILURE";

export const GET_SINGLE_MEMBER_SUCCESS = "GET_SINGLE_MEMBER_SUCCESS";
export const GET_SINGLE_MEMBER_FAILURE = "GET_SINGLE_MEMBER_FAILURE";

export const SET_MEMBERS_SEARCH_QUERY = "SET_MEMBERS_SEARCH_QUERY";
export const SET_MEMBERS_PAGE = "SET_MEMBERS_PAGE";

export const RESET_MEMBERS_REDUCER = "RESET_MEMBERS_REDUCER";
